import React, { FunctionComponent, ReactElement, createElement, useContext, useEffect, useRef, useState } from 'react';
import styles from "./splashScreen.module.scss";
import nowasteLogo from "../../../assets/icons/nowasteSplash.svg";

const SplashScreen = () => {

    useEffect(() => {
        (async () => {
            sessionStorage.setItem('splashed', 'true');
        })()
    }, [])

    return (
        <div className={styles.splashContainer}>
            <div></div>
            <div className={styles.mainSection}>
                <img src={nowasteLogo} className={styles.logo} alt='nowaste Logo' />
                <div className={styles.nowaste}>nowaste<span className={styles.crispySymbol}>©</span></div>
                <div className={styles.title}>a recycling <span style={{fontFamily: 'interBold'}}>Tournament</span></div>
            </div>
            <div className={styles.company}>by sustainablife<span className={styles.crispySymbol}>®</span></div>
        </div>
    );
};

export default SplashScreen;