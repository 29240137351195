import React, { Children, FunctionComponent, useEffect, useState } from "react";
import styles from "./screenTitle.module.scss";

interface ScreenTitleProps {
    /**
    * @property {} titles send up to 3 strings as string array
    */
    titles: string[];
    width?: string;
}

const ScreenTitle: FunctionComponent<ScreenTitleProps> = (props) => {
    return (
        <div className={styles.container}
            style={{
                width: props.width ?? ''
            }}>
            <span className={styles.microTitle}>{props.titles[0]}</span>
            <span className={styles.title}>{props.titles[1]}</span>
            <span className={styles.nanoTitle}>{props.titles[2]!}</span>
        </div>
    );
}

export default ScreenTitle;