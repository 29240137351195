import React, { Children, FunctionComponent, useContext, useEffect, useState } from "react";
import styles from "./menu.module.scss";
import Savior from "../../../assets/photos/Savior Icon.png";
import Modal from "../../../components/Modal/Modal";
import { Close } from "../../../components/icon/icons";
import { GlobalContext } from "../../../handlers/GlobalContext";
import { PopupsContext } from "../../../handlers/PopupsContext";

interface MenuProps {
    fontFamily?: string;
    onClose?: (value: any) => void
}

const Menu: FunctionComponent<MenuProps> = (props) => {

    const { showAlertPopup } = useContext(PopupsContext);

    const userAccount = localStorage.getItem("activeAccount");    
    const isLoggedIn = userAccount !== null;
    const username = userAccount !== null ? JSON.parse(userAccount).username : '';

    useEffect(() => {
    }, [])

    return (
        <Modal color='#EEEBDF' borderColor='red' zIndex='1000' onClick={() => {
            props.onClose!(false);
        }}>
            <div className={styles.menuContainer} >
                <div className={styles.closeButtonContainer}>
                    <Close height={'32px'} />
                </div>
                <div className={styles.menuItems}>
                    <div onClick={(e) => {
                        e.stopPropagation();
                        window.open('trashking.sustainablife.online')}}>
                        Home
                    </div>
                    <div onClick={(e) => {
                        e.stopPropagation();
                        window.open('trashking.sustainablife.online')}}>
                        SUPPORT
                    </div>
                    {!isLoggedIn &&
                        <>
                            <div className={styles.signupButton} onClick={() => { document.location = '/appSignup'; }}>
                                SIGNUP
                            </div>
                            <div className={styles.loginButton} onClick={() => { document.location = '/appLogin'; }}>
                                LOGIN
                            </div>
                        </>
                    }
                    {isLoggedIn &&
                            <div className={styles.loginButton} onClick={() => { document.location = '/profile'; }} >
                                PROFILE
                            </div>
                    }
                    <div onClick={(e) => {
                        e.stopPropagation();
                        window.open('trashking.sustainablife.online/gameencryption')}}>
                        GAME
                    </div>
                    <div className={styles.imageContainer} onClick={() => document.location = '/profile'}>
                        <img src={Savior} width='150px' />
                        <div className={styles.userTitle} >
                            <div>SAVIOR</div>
                            <div>{username!}</div>
                        </div>
                    </div>
                    {isLoggedIn &&
                        <div onClick={() => {
                            localStorage.removeItem('activeAccount');
                            sessionStorage.removeItem('landed');
                            showAlertPopup('logout completed!', 'message', 3000);
                        }}>
                            LOGOUT
                        </div>
                    }
                </div>
                <div></div>
            </div>
        </Modal>
    );
}

export default Menu;