import { FunctionComponent, ReactElement, ReactNode, useContext, useEffect, useRef, useState } from "react";
import styles from "./neighborhoodExtractor.module.scss";
import { JsxChild, JsxElement } from "typescript";
import { render } from "@testing-library/react";
import Button from "../../../components/Button/Button";
import { getProfile } from "../../../restAPI/user";
import { GlobalContext } from "../../../handlers/GlobalContext";
import { getRegionTotalStacks, getViewportActiveStacks } from "../../../restAPI/stackLocation";
import { Stacks } from "../../../components/icon/icons";
import ModalDialog from "../../../components/ModalDialog/ModalDialog";
//import { getDistance } from 'geolib';

interface NeighborhoodExtractorProps {

}

const NeighborhoodExtractor: FunctionComponent<NeighborhoodExtractorProps> = (props) => {

  const [mapData, setMapData] = useState<string>();

  const processMap = ((mapFile: File) => {
    if (mapFile) {
      console.log('1');
      var reader = new FileReader();
      reader.readAsText(mapFile, "UTF-8");
      reader.onloadend = (evt) => {
        const result = evt.target!.result;
          console.log(result);
          processNeighborhoods(result!.toString());
      }
      reader.onerror = (error) => {
          console.log("error reading file" + error);
      }
    }
  })

  const processNeighborhoods = async (fileData: string) => {
    const neighborhoods:any[] = await JSON.parse(fileData).features;
    if (neighborhoods !== undefined) {
      console.log('number of features: ' + neighborhoods.length);
      const cityNeighborhoods = neighborhoods.filter((item:any) => item.properties.NAME_1 === 'Istanbul' && item.properties.ENGTYPE_2 === 'District');
      console.log('number of neighborhoods: ' + cityNeighborhoods.length);
      cityNeighborhoods.map((neighborhood: any) => {        
        console.log(neighborhood);
        console.log('name: ' + neighborhood.properties.NAME_2);
        let coordinates:string[] = [];
        neighborhood.geometry.coordinates[0][0].map((coord:any) =>{
          //coordinates.push(coord[])
          console.log(coord[1] + ',' + coord[0]);
        });
      });
    }
  }

  return (
    <div style={{ display: 'flex', backgroundColor: '#c1cfc0' }}>
      <input id='mapFile' type="file" onChange={(event) => {
        if (event.target.files !== null) {
          processMap(event.target.files[0]);
        }
      }} />
    </div>
  );
}

export default NeighborhoodExtractor;