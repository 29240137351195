import React, { Children, FunctionComponent, ReactElement, useEffect, useState } from "react";
import styles from "./groupBox.module.scss";

interface GroupBoxProps {
    title: string;
    width: string;
    height: string;
    children?: React.ReactNode;
}

const GroupBox: FunctionComponent<GroupBoxProps> = (props) => {
    return (
        <div className={styles.container}
            style={{
                width: props.width,
                height: props.height
            }}>
            <div className={styles.border}>
                <div className={styles.titleContainer}>
                    <div className={styles.title}>
                        {props.title}
                    </div>
                </div>
                {props.children}
            </div>
        </div>
    );
}

export default GroupBox;