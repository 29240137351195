import { FunctionComponent } from "react";
import { Settings } from "../assets/statics/Settings";

export interface contact {
    uId: string | null;    
}

/**
 * 
 * @param userId 
 * @param type send % to retrieve all user contacts or one of 'ADDRESS'|'PHONE'|'CELL' parameters
 * @returns 
 */
export const userContact = async (
    userId: string,
    type: string): Promise<any | undefined> => {

    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/contact", {
            method: 'GET',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
                'queryData': JSON.stringify({
                    userId,
                    type
                })
            })
        }).then((response) => {
            response.json().then((result) => {
                console.log('-----==={ ' + result.result);
                resolve(result.result);
            }).catch((error) => { resolve(false) });
        }).catch((error) => {
            resolve(false);
        });
    });
}