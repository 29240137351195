import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import { DateCalendar, LocalizationProvider, TimeClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Modal from '../../../components/Modal/Modal';
import { Close } from '../../../components/icon/icons';
import ScreenTitle from '../../../components/inappComponents/ScreenTitles/ScreenTitle';
import GroupBox from '../../../components/GroupBox/GroupBox';
import Dropdown from '../../../components/DropDown/Dropdown';
import FormItem from '../../../components/FormItem/FormItem';
import Textbox from '../../../components/Textbox/Textbox';
import Button from '../../../components/Button/Button';
import { getUnits } from '../../../restAPI/unit';
import { getCategories } from '../../../restAPI/category';
import { Settings } from '../../../assets/statics/Settings';
import ToggleButton from '../../../components/ToggleButton/ToggleButton';
import Progress from '../../../assets/animated/progress.svg';

import styles from "./appointment.module.scss";
import stylesApp from "../app.module.scss";
import ModalDialog from '../../../components/ModalDialog/ModalDialog';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { GlobalContext } from '../../../handlers/GlobalContext';
import { info } from 'console';
import { PopupsContext } from '../../../handlers/PopupsContext';

interface AppointmentProps {
    totalWeight?: number | 0;
    spotCount?: number | 0;
    firstRedemption?: any;
    onOK: (pickupData: any) => void;
    onClose: () => void;
}

const Appointment: FunctionComponent<AppointmentProps> = (props) => {

    const { updateCategory, user, notifications, displayingCategory } = useContext(GlobalContext);
    const { showAlertPopup, showModal, showBusy, hideBusy } = useContext(PopupsContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
    const [showTimePicker, setShowTimePicker] = useState<boolean>(false);
    const [showStarts, setShowStarts] = useState<boolean>(true);
    const [showEnds, setShowEnds] = useState<boolean>(false);
    const [invalidDate, setInvalidDate] = useState<string>('');
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [pickupDate, setPickupDate] = useState<string | undefined>();
    const [pickupStarts, setPickupStarts] = useState<string>('00:00');
    const [pickupEnds, setPickupEnds] = useState<string>('00:00');
    const [categoryLabel, setCategoryLabel] = useState<string>('');
    const dateOptions: any = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    const today = new Intl.DateTimeFormat(undefined, dateOptions).format(new Date());

    useEffect(() => {
        (async () => {
            showBusy('loading', '');
            try {
                const categoryList = await getCategories();
                if (categoryList !== undefined) {
                    if (categoryList !== undefined) {
                        setCategoryLabel(JSON.parse(categoryList).result.filter((category: any) => { return (category.pkCategory === displayingCategory) })[0].displayable);
                    }
                }
            }
            catch (error) {
                showAlertPopup('server connection lost ...', 'error', 5000);
             }
            hideBusy();
        })()
    }, [])

    return (
        <Modal color='#D9D9D9'>
            <div className={stylesApp.screenContainer}>
                <div className={stylesApp.screenHeaderContainer}>
                    <div className={stylesApp.closeContainer}>
                        <Close height={'64px'} onClick={() => {
                            props.onClose();
                        }} />
                    </div>
                    <div className={stylesApp.separator}></div>
                    <ScreenTitle titles={['collection', 'Appointment']} width={'180px'} />
                </div>
                <GroupBox width={'85%'} height={'fit-content'} title={'Pick Up'}>
                    <div className={stylesApp.mixedDateControls}>
                        <FormItem title={'stack:'} width={'100%'} controlPos='line'>
                            <div className={styles.stackInfo}>
                                <div className={styles.totalWeight}>{props.totalWeight!} Kg</div>
                                <div className={styles.categoryName}>{categoryLabel!}</div>
                            </div>
                        </FormItem>
                    </div>
                    <div className={stylesApp.separator}></div>
                    <FormItem title={'distributed in:'} width={'90%'} controlPos='line' errorMessage={invalidDate}>
                        <div>{props.spotCount!} spots</div>
                    </FormItem>
                    <div className={stylesApp.separator}></div>
                    <div className={stylesApp.separator}></div>
                    <div className={stylesApp.mixedDateControls}>
                        <FormItem title={'collection date:'} width={'90%'} controlPos='right'>
                            <div>{pickupDate ?? 'not set'}</div>
                        </FormItem>
                        <div className={stylesApp.splitter}></div>
                        <Button width={'38px'} height={'38px'} border={'0px dotted #0A4000'} image={require('../../../assets/icons/edit.png')} color={'#D9D9D9'} imageWidth={'38px'} imageHeight={'38px'} onClick={() => { setShowDatePicker(true); }} />
                    </div>
                    <div className={stylesApp.separator}></div>
                    <div className={stylesApp.mixedDateControls}>
                        <FormItem title={'collection time (est.):'} width={'90%'} controlPos='right'>
                            <div>{pickupStarts} - {pickupEnds}</div>
                        </FormItem>
                        <div className={stylesApp.splitter}></div>
                        <Button width={'38px'} height={'38px'} border={'0px dotted #0A4000'} image={require('../../../assets/icons/edit.png')} color={'#D9D9D9'} imageWidth={'38px'} imageHeight={'38px'} onClick={() => { setShowTimePicker(true); }} />
                    </div>
                    <div className={stylesApp.separator}></div>
                    <div className={stylesApp.separator}></div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', position: 'absolute', bottom: '-21px', opacity: 1 }}>
                        <Button width='170px' height='42px' text='take bids' color='#002121' textColor='#FFFFFF' fontFamily='interBold' fontSize='18px'
                            onClick={() => {
                                if (pickupDate !== undefined) {
                                    const date = pickupDate.split('/');
                                    const pickupDTStarts = new Date(Date.parse(date[0] + ' ' + date[1] + ' ' + date[2] + ' ' + pickupStarts)).toISOString();
                                    const pickupDTEnds = new Date(Date.parse(date[0] + ' ' + date[1] + ' ' + date[2] + ' ' + pickupEnds)).toISOString();
                                    props.onOK({ 'pickupStart': pickupDTStarts, 'pickupEnd': pickupDTEnds });
                                }
                            }
                            } />
                    </div>
                    {showMessage &&
                        <ModalDialog title={'information.'} body={'Your appointment sent to consumers, you can collect your stacks on requeted date!'} okLabel={'continue'} onCancel={() => setShowMessage(false)}></ModalDialog>
                    }
                </GroupBox>
                <div className={stylesApp.separator}></div>
                <div className={stylesApp.separator}></div>
            </div>

            {showDatePicker &&
                <Modal>
                    <div className={stylesApp.timePicker}>
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateCalendar onChange={(value) => {
                                    setPickupDate(value.$y + '/' + value.$M + '/' + value.$D);
                                }}></DateCalendar>
                            </LocalizationProvider>
                        </div>
                        <div className={stylesApp.mixedDateControls}>
                            <div className={styles.splitter}></div>
                            <Button width='130px' height='42px' text='cancel' borderColor='#002121' textColor='#002121' fontFamily='interBold' fontSize='18px' onClick={() => setShowDatePicker(false)} />
                            <div className={styles.splitter}></div>
                            <Button width='130px' height='42px' text='set' borderColor='#002121' textColor='#002121' fontFamily='interBold' fontSize='18px' onClick={() => setShowDatePicker(false)} />
                            <div className={styles.splitter}></div>
                        </div>
                    </div>
                </Modal>
            }

            {showTimePicker &&
                <Modal>
                    <div className={stylesApp.timePicker}>
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                {showStarts &&
                                    <TimeClock ampm={false} onChange={(value) => { setPickupStarts(value.$H + ':' + value.$m) }}></TimeClock>
                                }
                                {showEnds &&
                                    <TimeClock ampm={false} onChange={(value) => { setPickupEnds(value.$H + ':' + value.$m) }}></TimeClock>
                                }
                            </LocalizationProvider>
                        </div>
                        <div className={styles.displayingClock} >
                            <div onClick={() => {
                                setShowStarts(true);
                                setShowEnds(false);
                            }}>
                                {pickupStarts}
                            </div>
                            <div style={{ padding: '10px' }} > - </div>
                            <div onClick={() => {
                                setShowStarts(false);
                                setShowEnds(true)
                            }}>
                                {pickupEnds}
                            </div>
                        </div>
                        <div className={stylesApp.mixedDateControls}>
                            <div className={styles.splitter}></div>
                            <Button width='130px' height='42px' text='cancel' borderColor='#002121' textColor='#002121' fontFamily='interBold' fontSize='18px' onClick={() => setShowTimePicker(false)} />
                            <div className={styles.splitter}></div>
                            <Button width='130px' height='42px' text='set' borderColor='#002121' textColor='#002121' fontFamily='interBold' fontSize='18px' onClick={() => setShowTimePicker(false)} />
                            <div className={styles.splitter}></div>
                        </div>
                    </div>
                </Modal>
            }
        </Modal>
    );
}

export default Appointment;