import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./stackDetails.module.scss";
import stylesApp from "../../app.module.scss";

import { Close } from '../../../../components/icon/icons';
import ScreenTitle from '../../../../components/inappComponents/ScreenTitles/ScreenTitle';
import GroupBox from '../../../../components/GroupBox/GroupBox';
import Dropdown from '../../../../components/DropDown/Dropdown';
import FormItem from '../../../../components/FormItem/FormItem';
import Textbox from '../../../../components/Textbox/Textbox';
import Button from '../../../../components/Button/Button';
import { getCategories } from '../../../../restAPI/category';
import { DateCalendar, LocalizationProvider, TimeClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'react-image-picker-editor/dist/index.css'
import Progress from '../../../../components/progress/Progress';
import LargeImage from '../../../../components/LargeImage/LargeImage';
import { removeTempStackPhotos, recordStackPhoto, removeTempStackPhoto, recordStack } from '../../../../restAPI/stack';
import { GlobalContext } from '../../../../handlers/GlobalContext';
import { userContact } from '../../../../restAPI/contact';
import Modal from '../../../../components/Modal/Modal';
import { PopupsContext } from '../../../../handlers/PopupsContext';


interface StackDetailsProps {
    stackId: string;
    onClose?: () => void;
}

const StackDetails: FunctionComponent<StackDetailsProps> = (props) => {

    const { updateCategory, user, notifications } = useContext(GlobalContext);
    const { showAlertPopup, showModal, showBusy, hideBusy } = useContext(PopupsContext);

    const initialRef: any = null;
    const stackFilePicker = useRef(initialRef);

    // const [userID, setUserID] = useState<string | undefined>();
    const [categories, setCategories] = useState<any>('');
    const [selectedCategory, setSelectedCategory] = useState<any>('');    
    const [volume, setVolume] = useState<any>('');
    const dateOptions: any = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    const today = new Intl.DateTimeFormat(undefined, dateOptions).format(new Date());
    const [loading, setLoading] = useState<boolean>(false);
    const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
    const [showTimePicker, setShowTimePicker] = useState<boolean>(false);
    const [redeemDate, setRedeemDate] = useState<string>('');
    const [redeemTime, setRedeemTime] = useState<string>('12:00');
    const [showLargeImage, setShowLargeImage] = useState<boolean>(false);
    const [largeImage, setLargeImage] = useState<string>('');
    const [photos, setPhotos] = useState<any[]>([
        require('../../../../assets/photos/dummyRemovable/dummyStack0.jpg'),
        require('../../../../assets/photos/dummyRemovable/dummyStack1.jpg'),
        require('../../../../assets/photos/dummyRemovable/dummyStack2.jpg'),
        require('../../../../assets/photos/dummyRemovable/dummyStack3.jpg'),
        require('../../../../assets/photos/dummyRemovable/dummyStack4.jpg'),
        require('../../../../assets/photos/dummyRemovable/dummyStack5.jpg')]);
    const [uploading, setUploading] = useState<boolean>(false);
    
    const [invalidDate, setInvalidDate] = useState<string>('');
    
    let time = 0;

    useEffect(() => {
        (async () => {
            showBusy('loading', '');
            setLoading(true);            
            try {
                const categoryList = await getCategories();
                if (categoryList !== undefined) {
                    setCategories(JSON.parse(JSON.stringify(JSON.parse(categoryList).result!.sort((a: any, b: any) => { return (a.displayable > b.displayable ? 1 : -1) })).replaceAll('pkCategory', 'value').replaceAll('displayable', 'label')));
                }
            } catch (error) {
                showAlertPopup('server connection lost ...', 'error', 5000);
            }
            setLoading(false);
            hideBusy();
        })()
    }, [])

    useEffect(() => {
    }, [uploading])


    const addStackPhoto = (dataUri: any) => {
        if (dataUri !== null) {
            setUploading(true);
            let photoJSON = null;
            let activeIndex = photos.length;
            const photoURL = URL.createObjectURL(dataUri);
            photoJSON = JSON.stringify({ 'id': null, 'photo': photoURL, 'index': activeIndex });
            const photosTemp = [...photos];//spread operator
            photosTemp.push(photoJSON);
            setPhotos(photosTemp);

            const reader = new FileReader();
            reader.onloadend = async () => {
                const base64Image = reader.result!.toString().split(',')[1];
                const result = await recordStackPhoto(undefined, undefined, user!.pkUser, base64Image, dataUri, activeIndex);

                const resultJSON = JSON.parse(result);
                photosTemp[resultJSON.index] = JSON.stringify({ 'id': resultJSON.result === undefined ? 'undefined' : resultJSON.result, 'photo': JSON.parse(photosTemp[resultJSON.index]).photo, 'index': resultJSON.index });
                setPhotos(photosTemp);
                setUploading(false);
            }
            reader.readAsDataURL(dataUri);
        }
    }

    const removeStackPhoto = (index: number) => {
        const photosTemp = [...photos];
        photosTemp.splice(index, 1);
        const photoID = JSON.parse(photos[index]).id;        
        if (photoID !== 'undefined')            
            removeTempStackPhoto(photoID, user!.pkUser);
        setPhotos(photosTemp);
    }

    const addStack = async () => {
        showBusy('saving', 'Saving ...');
        try {
            if (user === undefined) {
                showAlertPopup('please first LOGIN to your account!', 'error', 3000);
                return;
            }
            const contact = await userContact(user.pkUser, 'ADDRESS');
            const redeemsAt = new Date(Number(redeemDate.split('/')[0]), Number(redeemDate.split('/')[1]), Number(redeemDate.split('/')[2]), Number(redeemTime.split(':')[0]), Number(redeemTime.split(':')[1])).toISOString();
            const result = await recordStack(contact[0].pkContact, selectedCategory, volume, redeemsAt);
            console.log('-----==={ addStack res: ' + result.stackId + ' by USER: ' + user.pkUser);
            if (result) {
                if (result.linkedPhotos === photos.length) {
                    console.log('wrote: ' + result.linkedPhotos + ' from ' + photos.length);
                }
                showAlertPopup('stack report done!', 'message', 3000);
                props.onClose!();
            }
            else if (result === undefined)
                showAlertPopup('something went wrong!', 'error', 3000);
        }
        catch (error) {
            console.log(error);
            showAlertPopup('something went wrong!', 'error', 3000);
        }
        hideBusy();
    }

    if (showLargeImage)
        return (
            <Modal onClick={() => setShowLargeImage(false)} glassEffect={true}>
                <LargeImage image={largeImage} onClose={() => { setShowLargeImage(false) }} />
            </Modal>);
    else
        return (
            <Modal color='#D9D9D9'>
                <div className={stylesApp.screenContainer}>
                    <div className={stylesApp.screenHeaderContainer}>
                        <div className={stylesApp.closeContainer}>
                            <Close height={'64px'} onClick={() => {
                                props.onClose!();
                            }} />
                        </div>
                        <ScreenTitle titles={['stack', 'Details']} width={'95px'} />
                    </div>
                    <GroupBox width={'85%'} height={'fit-content'} title={'Details'}>
                        <div className={styles.consumerInfo}>
                            <div className={styles.consumerFullName}>{'Pinar Gulshan'}</div>
                            <div className={styles.consumerPhoto}>
                                <img className={styles.img} src={require('../../../../assets/photos/dummyRemovable/dummy' + Math.floor(Math.random() * 4) + '.jpg')} />
                            </div>
                        </div>

                        <div className={styles.location}>
                            <div className={styles.label}>
                                {'stack location'}
                            </div>
                            <div className={styles.address}>
                                {'Unit 3, No 23, Gunsay ave, Fatih mahalla'}
                            </div>
                        </div>
                        <div className={stylesApp.separator}></div>
                        <div className={styles.deliveryCode}>
                            <div className={styles.label}>
                                {'delivery Code:'}
                            </div>
                            <div className={styles.code}>
                                {'254698'}
                            </div>
                        </div>
                        <div className={stylesApp.separator}></div>
                        <div className={styles.other}>
                            <div className={styles.label}>
                                {'redeems:'}
                            </div>
                            <div className={styles.data}>
                                {'1.5 hours'}
                            </div>
                        </div>
                        <div className={stylesApp.separator}></div>
                        <div className={styles.other}>
                            <div className={styles.label}>
                                {'initial weight:'}
                            </div>
                            <div className={styles.data}>
                                {'10Kg'}
                            </div>
                        </div>                        
                        <div className={stylesApp.separator}></div>
                        
                        <div className={styles.images}>                            
                            <div className={styles.noImage}>
                            </div>
                            {
                                photos.map((imageData: string, index: number) => {
                                    
                                    //const photoJSON = JSON.parse(imageData);
                                    //console.log(photos);
                                    //console.log('MAPPING photo no.(' + photoJSON.index + ',' + index + ')>>>>>>> ' + photoJSON.id);
                                    return (
                                        <div className={styles.imagePlaceholder} onClick={() => {                                                
                                                    setLargeImage(imageData);
                                                    setShowLargeImage(true);
                                                }
                                            }>
                                            <img src={(imageData)}/>
                                        </div>
                                        // <div className={styles.imagePlaceholder}>
                                        //     <img src={require(imageData)}/>
                                            // onClick={() => {
                                            //     if (photoJSON.id !== 'undefined') {
                                            //         setLargeImage(photoJSON.photo);
                                            //         setShowLargeImage(true);
                                            //     }
                                            // }}>
                                            // <img src={photoJSON.photo} key={photoJSON.id} />
                                            // <Button overlaySpec={photoJSON.id === 'undefined' ? 'center-center' : 'top-right'} color='transparent'
                                            //     border='0px solid transparent'
                                            //     width={photoJSON.id === 'undefined' ? '100%' : '24px'}
                                            //     height={photoJSON.id === 'undefined' ? '100%' : '24px'}
                                            //     imageWidth={photoJSON.id === 'undefined' ? '80%' : '24px'}
                                            //     imageHeight={photoJSON.id === 'undefined' ? '80%' : '24px'}
                                            //     image={photoJSON.id === 'undefined' ? require('../../../../assets/icons/alertedRemove.png') : require('../../../../assets/icons/closeRed.png')}
                                            //     onClick={(e) => {
                                            //         e.stopPropagation();
                                            //         removeStackPhoto(index);
                                            //     }} key={'btn' + photoJSON.id} />
                                            // {(photoJSON.id === null) &&
                                            //     <Progress opacity={0.9} />
                                            // }
                                        //</div>
                                    )
                                })
                            }
                        </div>
                        <div className={stylesApp.separator}></div>                        
                        <FormItem title={'agreed weight:'} width={'100%'}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <Textbox width={'100%'} height={'42px'} placeHolder='0' 
                                    onChange={(value) => {
                                        console.log('vol ' + value);     
                                        setVolume(value);
                                    }} />
                                <div className={stylesApp.splitter}></div>
                                Kg
                            </div>
                        </FormItem>
                        <div className={stylesApp.separator}></div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', position: 'absolute', bottom: '-21px', opacity: 1 }}>
                            <Button width='170px' height='42px' text='done' color='#002121' textColor='#FFFFFF' fontFamily='interBold' fontSize='18px' onClick={() => { addStack() }} />
                        </div>
                    </GroupBox>
                    <div className={stylesApp.separator}></div>
                    <div className={stylesApp.separator}></div>
                </div>

                {showDatePicker &&
                    <Modal>
                        <div className={stylesApp.timePicker}>
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateCalendar onChange={(value) => {
                                        setRedeemDate(value.$y + '/' + value.$M + '/' + value.$D);
                                    }}></DateCalendar>
                                </LocalizationProvider>
                            </div>
                            <div className={stylesApp.mixedDateControls}>
                                <div className={styles.splitter}></div>
                                <Button width='130px' height='42px' text='cancel' borderColor='#002121' textColor='#002121' fontFamily='interBold' fontSize='18px' onClick={() => setShowDatePicker(false)} />
                                <div className={styles.splitter}></div>
                                <Button width='130px' height='42px' text='set' borderColor='#002121' textColor='#002121' fontFamily='interBold' fontSize='18px' onClick={() => setShowDatePicker(false)} />
                                <div className={styles.splitter}></div>
                            </div>
                        </div>
                    </Modal>
                }

                {showTimePicker &&
                    <Modal>
                        <div className={stylesApp.timePicker}>
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimeClock ampm={false} onChange={(value) => { setRedeemTime(value.$H + ':' + value.$m) }}></TimeClock>
                                </LocalizationProvider>
                            </div>
                            <div className={styles.displayingClock} >
                                <div onClick={() => {
                                }}>
                                    {redeemTime}
                                </div>
                            </div>
                            <div className={stylesApp.mixedDateControls}>
                                <div className={styles.splitter}></div>
                                <Button width='130px' height='42px' text='cancel' borderColor='#002121' textColor='#002121' fontFamily='interBold' fontSize='18px' onClick={() => setShowTimePicker(false)} />
                                <div className={styles.splitter}></div>
                                <Button width='130px' height='42px' text='set' borderColor='#002121' textColor='#002121' fontFamily='interBold' fontSize='18px' onClick={() => setShowTimePicker(false)} />
                                <div className={styles.splitter}></div>
                            </div>
                        </div>
                    </Modal>
                }
            </Modal>
        );
};

export default StackDetails;