import { FunctionComponent, useContext, useEffect, useRef, useState } from "react";
import styles from "./nowasteMap.module.scss";
import Button from "../../../components/Button/Button";
import { GlobalContext } from "../../../handlers/GlobalContext";
import { getRegionTotalStacks, getViewportActiveStacks, setPickupAppointments } from "../../../restAPI/stackLocation";
import mapFile from "../../../assets/data/nowasteMap_Turkey.json";

import Appointment from "../appointment/appointment";
import { PopupsContext } from "../../../handlers/PopupsContext";

interface NowasteMapProps {
  center: google.maps.LatLngLiteral;
  zoom: number;
  showAddressPin?: boolean;
  onClick?: (value: any) => void;
}

const polygonStrokeColor = '#09DF09';
const polygonStrokeOpacity = .8;
const polygonStrokeWeight = .5;
const polygonFillColor = '#09DF09';
const polygonFillOpacity = 0;//.35;

const hexagonDiameter = 1.5;//diameter of region hexagon vertical in (Km)

const NowasteMap: FunctionComponent<NowasteMapProps> = (props) => {

  const { user, displayingCategory } = useContext(GlobalContext);  
  const { showAlertPopup, showModal, showBusy, hideBusy } = useContext(PopupsContext);

  let map: any = null;
  let neighborhoodsInbound: number[] = [];
  const mapRef = useRef<any>(null);
  const activeMarkers: any[] = [];
  const neighborhoodPolygons: google.maps.Polygon[] = [];
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showAppointment, setShowAppointment] = useState<boolean>(false);

  //pickup appointment data
  const [stacksVolume, setStacksVolume] = useState<number>();
  const [stacksSpotCount, setStacksSpotCount] = useState<number>();
  const [stacksFirstRedemptionAt, setStacksFirstRedemptionAt] = useState<number>();
  const [regionStackIdsList, setRegionStackIdsList] = useState<string>();

  let alerted: boolean | false = JSON.parse(localStorage.getItem('zoomAlerted')!);

  useEffect(() => {    
    initMap();    
  }, []);

  useEffect(() => {
    showBusy('loading', 'loading region stacks ...')

    if (map === null)
      initMap();

    if (map !== null) {
      updateOutput();
    }
    hideBusy();
  }, [displayingCategory, showAppointment]);//ToDO: add  

  const initMap = async () => {   
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const center = props.center;
    const zoom = props.zoom;
    if (map === null) {
      map = new Map(mapRef.current, {
        center, zoom, zoomControl: false, fullscreenControl: false, keyboardShortcuts: false, rotateControl: true,
        clickableIcons: false,
        // minZoom: zoom,
        // maxZoom: zoom + 10,
        styles: [{
          "featureType": "poi",
          "stylers": [
            { "visibility": "off" }
          ]
        }],
        mapId: '84127409238740832174082'
      });
    }

    if (map) {
      loadNeighborhoods();
    }

    // map.addListener('dblclick', () => {
    //   //regionizeMap();
    // });

    if (props.showAddressPin) {
      const centerIcon = document.createElement("img");
      centerIcon.src = require('../../../assets/icons/mapPinAddress.png');
      centerIcon.width = 64;
      centerIcon.height = 64;

      const markerCenter = new AdvancedMarkerElement({
        position: center,
        map: map,
        content: centerIcon
      });

      map.addListener('center_changed', () => {
        markerCenter.position = map.getCenter();
        if (props.onClick)
          props.onClick(map.getCenter()!.lat() + ',' + map.getCenter()!.lng());
      });

    }
    else {
      map.addListener('idle', () => {
        updateOutput();
      });
    }    
  }

  const detectInboundNeighborhoods = async (bounds: any) => {
    if (bounds !== undefined) {
      neighborhoodsInbound = [];
      neighborhoodPolygons.map((neighborhood, index) => {
        const polygonVertices = neighborhood.getPath().getArray();
        for (let vertexCounter = 0; vertexCounter < polygonVertices.length; vertexCounter++) {
          if (google.maps.geometry !== undefined) {
            if (bounds.contains(polygonVertices[vertexCounter]) || google.maps.geometry.poly.containsLocation(map.getCenter(), neighborhood)) {
              neighborhoodsInbound.push(index);              
              return;
            }
          }
        }
      })
    }
  }

  const updateOutput = async () => {
    clearMarkers();
    console.log('number of MARKERS: ' + activeMarkers.length + alerted);

    // if (map.zoom < 12 && !alerted) {
    //   alerted = true;
    //   localStorage.setItem('zoomAlerted', 'true');
    //   showAlertPopup('choose a category, zoom & drag to see neighborhood total stacks!', 'info', 5000);      
    // }
    // if (map.zoom < 12){
    //   drawRegionalMarkers();
    // }
    // else if (12 <= map.zoom && map.zoom <= 13) {
    //   drawRegionalMarkers();
    // }
    if (map.zoom <= 12){
      console.log('MAP ZOOM +/-: ' + map.zoom);
      drawRegionalMarkers();
    }
    else if (12 < map.zoom) {      
      console.log('MAP ZOOM -/+: ' + map.zoom);
      drawStackMarkers();
    }
  }

  const loadNeighborhoods = async () => {    
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    
    const neighborhoods: any[] = mapFile["features"];// JSON.parse(fileData).features;
    if (neighborhoods !== undefined) {
      //console.log('number of features: ' + neighborhoods.length);
      const cityNeighborhoods = neighborhoods.filter((item: any) => item.properties.NAME_1 === 'Istanbul' && item.properties.ENGTYPE_2 === 'District');
      console.log('number of neighborhoods: ' + cityNeighborhoods.length);
      cityNeighborhoods.map((neighborhood: any) => {
        
        const vertices: google.maps.LatLng[] = [];
        neighborhood.geometry.coordinates[0][0].map((coord: any) => {
          vertices.push(new google.maps.LatLng(coord[1], coord[0]));          
        });

        if (map !== null) {
          let currentPolygon = new google.maps.Polygon({
            map,
            paths: vertices,
            strokeColor: polygonStrokeColor,
            strokeOpacity: polygonStrokeOpacity,
            strokeWeight: polygonStrokeWeight,
            fillColor: polygonFillColor,
            fillOpacity: polygonFillOpacity,

          });
        
          neighborhoodPolygons.push(currentPolygon);
        }
        else
          console.log(' >>>>>>>>>>>>>>>>>>>>>>>>>> BIG no map FAiLURE');
      });
    }
    console.log('---={ Drawing ' + neighborhoodPolygons.length + ' neighborhoods');
    console.log('---={ Drawing ' + activeMarkers.length + ' marker');
    drawRegionalMarkers();
  }

  const drawStackMarkers = async () => {
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

    const bounds = await map.getBounds();
    detectInboundNeighborhoods(bounds);

    showBusy('loading', '');
    if (displayingCategory !== undefined) {
      for (let cnt = 0; cnt < neighborhoodsInbound.length; cnt++) {

        const regionHexagon = neighborhoodPolygons[neighborhoodsInbound[cnt]];
        try {
          const result = await getViewportActiveStacks(
            regionHexagon.getPath().getArray(),
            displayingCategory);

          const stacksResult = (result === undefined ? result : await JSON.parse(result).result);

          if (stacksResult !== undefined) {
            await stacksResult.map((stackItem: any) => {

              if (stackItem.location !== undefined) {
                let lat = stackItem.location!.split(',')[0],
                  lng = stackItem.location!.split(',')[1];

                const markerContent = document.createElement("div");
                markerContent.className = styles.detailDataBox;
                markerContent.innerHTML = `<div>${stackItem.volume} Kg</div>`;

                const currentMarker = new AdvancedMarkerElement({
                  position: new google.maps.LatLng(
                    lat,
                    lng
                  ),
                  map: map,
                  content: markerContent,
                });
                currentMarker.addListener('click', () => {
                  if (user) {
                    if (user.accountType === 'consumer') {
                      // setRegionStackIdsList(stacksResult.stackContentIds);
                      // loadAppointmentScreen(stacksResult.volume, stacksResult.spotCount, stacksResult.firstRedemptionAt);
                    }
                  }
                });
                activeMarkers.push(currentMarker);
              }
            });
          }
        }
        catch (error) {
          console.log(error);
        }
      }
    }
    hideBusy();
  }

  const drawRegionalMarkers = async () => {
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    
    const bounds = await map.getBounds();
    detectInboundNeighborhoods(bounds);    
    
    showBusy('loading', '');    
    if (displayingCategory !== undefined) {
      for (let cnt = 0; cnt < neighborhoodsInbound.length; cnt++) {
        
        const regionHexagon = neighborhoodPolygons[neighborhoodsInbound[cnt]];
        try {
          //console.log(`\n\n\n\n SPOTTING: ${neighborhoodsInbound[cnt]} ${regionHexagon} \n\n\n\n`);
          const result = await getRegionTotalStacks(
            regionHexagon.getPath().getArray(),
            displayingCategory);
            
          const stacksResult = (result === undefined ? result : await JSON.parse(result).result[0]);

          if (stacksResult !== undefined) {

            let lat, lng;
            lat = stacksResult.location!.split(',')[0];
            lng = stacksResult.location!.split(',')[1];

            const firstRedeems = stacksResult.firstRedemptionAt.split('-');
            const redemptionDate = firstRedeems[0].substring(1, 3) + '/' + firstRedeems[1] + '/' + firstRedeems[2].substring(0, 2);

            const markerContent = document.createElement("div");
            markerContent.className = styles.overallDataBox;
            markerContent.innerHTML = `
                        <img src='${require('../../../assets/icons/stacksMap.png')}' class='${styles.package}' />
                        <div class='${styles.packageInfo}'>
                          <div class='${styles.packageWeight}'>${stacksResult.volume} Kg</div>
                          <div class='${styles.packageFirstRedemption}'>${redemptionDate}</div>
                        </div>`;

            const currentMarker = new AdvancedMarkerElement({
              position: new google.maps.LatLng(
                stacksResult.location.split(',')[0],
                stacksResult.location.split(',')[1]
              ),
              map: map,
              content: markerContent,
            });
            currentMarker.addListener('click', () => {
              if (user) {                
                if (user.accountType === 'collector' || user.accountType === null) { //ToDO remove 'user.accountType === null' for production                  
                  setRegionStackIdsList(stacksResult.stackContentIds);
                  loadAppointmentScreen(stacksResult.volume, stacksResult.spotCount, stacksResult.firstRedemptionAt);
                }
              }
              else
                document.location = '/appLogin';
            });
            activeMarkers.push(currentMarker);
          }

        }
        catch (error) {
          console.log('getRegionTotalStacks ============ ' + error);
          showAlertPopup('server connection lost ...' + error, 'error', 5000);
          hideBusy();
          return;
        }
      }
      //);
    }
    hideBusy();
  }

  const clearMarkers = () => {
    //return(new Promise((resolve, reject) => {
    activeMarkers.map((item) => {
      item.setMap(null);
    });
    while (activeMarkers.length > 0)
      activeMarkers.pop();
    //   resolve(true);
    // }));    
  }

  const clearPolygons = () => {
    neighborhoodPolygons.map((item) => {
      item.setMap(null);
    });
    while (neighborhoodPolygons.length > 0)
      neighborhoodPolygons.pop();
  }

  const loadAppointmentScreen = (stacksVolume: number, stacksSpotCount: number, stacksFirstRedemptionAt: any) => {
    setStacksVolume(stacksVolume);
    setStacksSpotCount(stacksSpotCount);
    setStacksFirstRedemptionAt(stacksFirstRedemptionAt);
    setShowAppointment(true);
  }

  const announceRegionPickUp = (regionPolygon: any, category: string) => {
    setShowConfirmation(true);
  }

  const setUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position);
        map.setCenter(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));
      }, (error) => {

        showAlertPopup('production mode only!', 'info', 3000);
      });
    }
    else
      console.log('no Navigators detected!');
  }

  return (
    <>
      <div ref={mapRef} id="map" style={{ width: '100vw', height: '100vh', position: 'relative', zIndex: '10' }}
        onClick={(e) => {
          if (map && props.onClick) {
            const center = map.getCenter();
            props.onClick(center.lat() + ',' + center.lng());
          }
        }} />
      <div style={{ position: 'absolute', zIndex: '10', right: '20px', bottom: '120px', borderRadius: '50%', backgroundColor: '#c1cfc0' }}>
        <Button width={'64px'} height={'64px'} imageWidth={'32px'} imageHeight={'32px'} color={'transparent'} border={'0px'} image={require('../../../assets/icons/currentLocation.png')} onClick={() => setUserLocation()} />
      </div>

      {showAppointment &&
        <Appointment totalWeight={stacksVolume} spotCount={stacksSpotCount} firstRedemption={stacksFirstRedemptionAt}
          onOK={(pickupDetails) => {            
            setShowAppointment(false);
            showModal('pickup.', 'send pickup appointment to collectors?', 'ok',
              () => {
                if (regionStackIdsList!) {
                  setPickupAppointments(regionStackIdsList, user.pkUser, pickupDetails);
                  let localDateStart = new Date(pickupDetails.pickupStart);
                  let localDateEnd = new Date(pickupDetails.pickupEnd);
                  showAlertPopup(`neighborhood collectors are notified that you will pick their stacks on ${localDateStart.getFullYear()}/${localDateStart.getMonth()}/${localDateStart.getDate()} between ${localDateStart.getHours()}:${localDateStart.getMinutes()}  - ${localDateEnd.getHours()}:${localDateEnd.getMinutes()}`, 'message', 5000);
                  //regionStackIdsList props.onOK(JSON.stringify({ 'date': pickupDate, 'timeStarts': pickupStarts, 'timeEnds': pickupEnds }));                
                }
                else
                  showAlertPopup(`stack details of neighborhood is not available`, 'error', 5000);
              },
              () => {
                showAlertPopup('message not sent', 'error', 3000);
              }
            );
            
          }}
          onClose={() => {
            setShowAppointment(false);
            showAlertPopup('no pickup appointments is set, due to your choice!', 'message', 5000);
          }}
        />
      }
    </>
  );
}

export default NowasteMap;