import React, { Children, FunctionComponent, ReactElement, useEffect, useState } from "react";
import styles from "./list.module.scss";

interface ListProps {
    children?: React.ReactNode;
}

const List: FunctionComponent<ListProps> = (props) => {

    useEffect(() => {
    })

    
    return (
        <div className={styles.container}>
            {props.children}
        </div>
    );
}

export default List;