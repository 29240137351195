import React, { Children, FunctionComponent, useEffect, useState } from "react";
import styles from "./modalDialog.module.scss";
import { inherits } from "util";
import Modal from "../Modal/Modal";
import { Close } from "../icon/icons";
import Button from "../Button/Button";

interface ModalProps {
    title?: string;
    body?: string;
    okLabel?: string;
    /**
     * @property {property} description supports multiple items, semi colon character ';' works as separator
    */
    description?: string;
    fontFamily?: string;
    onOk?: (value: any) => void
    onCancel?: () => void
    children?: React.ReactNode;
}

const ModalDialog: FunctionComponent<ModalProps> = (props) => {
    return (
        <Modal color='#EEEBDF' borderColor='red'>
            <div className={styles.dialogContainer} style={{}}>
                <div className={styles.frame}>
                    <div className={styles.titleBar}>
                        <div className={styles.title}>{props.title}</div>
                        <div className={styles.closeButton}
                            onClick={() => {
                                if (props.onCancel!)
                                    props.onCancel();
                            }}
                        >
                            <Close height='32px' />
                        </div>
                    </div>
                    <div className={styles.body}>
                        {props.body}
                    </div>
                    {props.description &&
                        <div className={styles.description}>
                            {
                                props.description.split(';').map((item) => {
                                    return (<div className={styles.descriptionItem}>{item}</div>)
                                })}
                        </div>
                    }
                    <div className={styles.spacer}></div>                    
                    <div className={styles.buttons}>
                        <Button text={props.okLabel}
                            onClick={async (event) => {
                                event.stopPropagation();                                
                                if(props.onOk!)
                                    props.onOk(event);
                            }}
                            width='170px' height={'42x'} fontSize='18px' fontFamily='interSemiBold' color='#ffffff' />
                    </div>
                </div>
                {/* {props.children} */}
            </div>
        </Modal>
    );
}

export default ModalDialog;