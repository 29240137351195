import React, { FunctionComponent, createRef, useEffect, useState } from "react";
import styles from "./largeImage.module.scss";
import stylesApp from "../../pages/trashkingApp/app.module.scss";
import Modal from "../Modal/Modal";

interface LargeImageProps {
    image: string;
    onClose?: () => void;
}

const LargeImage: FunctionComponent<LargeImageProps> = (props) => {

    return (
        // <Modal glassEffect={true}>
            <img style={{maxWidth: '100%', maxHeight: '100%'}} src={props.image} onClick={() => {
                props.onClose!();
            }} />
        // </Modal>
    );
}

export default LargeImage;