import React, { Children, FunctionComponent, ReactElement, useEffect, useState } from "react";
import styles from "./formItem.module.scss";
import { red } from "@mui/material/colors";

interface FormItemProps {
    title: string;
    titleDescription? : string;
    width?: string;
    controlPos?: 'left' | 'right' | 'line' | undefined;
    errorMessage?: string;
    children?: React.ReactNode;
    onDescriptionClick?: () => void;
}

const FormItem: FunctionComponent<FormItemProps> = (props) => {

    const [controlPos, setControlPos] = useState<string>(props.controlPos!);

    useEffect(() => {
        console.log(props.controlPos!);
        switch (props.controlPos!) {
            case 'right':
                setControlPos('flex-end');
                break;
            case 'left':
                setControlPos('flex-start');
                break;
            case 'line':
                setControlPos('flex-end');
                break;
        }
    })

    
    return (
        <div className={styles.container}
            style={{
                width: props.width!,
                flexDirection: (props.controlPos ? (props.controlPos! === 'line' ? 'row' : 'column') : 'column'),
                alignItems: (props.controlPos ? (props.controlPos! === 'line' ? 'space-between' : 'center') : 'center'),
                justifyContent: (props.controlPos ? (props.controlPos! === 'line' ? 'space-between' : 'center') : 'center'),
            }}>
            <div className={styles.title}
             style={{ 
                width: (props.controlPos ? (props.controlPos! === 'line' ? '50%' : '100%') : '100%'),
            }}            
            >{props.title}<span onClick={() => {props.onDescriptionClick!()}} className={styles.titleDescription}>&nbsp;{props.titleDescription!}{props.titleDescription ? 'ⓘ' : ''}</span></div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: (props.controlPos ? (props.controlPos! === 'line' ? '50%' : '100%') : '100%'),
                alignItems: 'center',
                justifyContent: controlPos!
            }}>
                {props.children}
            </div>
            {props.controlPos! === 'line' && 
            <div>
                <div className={styles.errorMessage}>{props.errorMessage!}</div>            
            </div>}
        </div>
    );
}

export default FormItem;