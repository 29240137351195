import { googleSignup } from "../restAPI/googleSignup";

const oauthSignIn = () => {
  // Google's OAuth 2.0 endpoint for requesting an access token
  var oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

  // Create <form> element to submit parameters to OAuth 2.0 endpoint.
  var form = document.createElement('form');
  form.setAttribute('method', 'GET'); // Send as a GET request.
  form.setAttribute('action', oauth2Endpoint);

  // Parameters to pass to OAuth 2.0 endpoint.
  var params = {
    'client_id': '103719573718-s86m327ga2pcngtsjl762k7a3hli94n5.apps.googleusercontent.com',
    'redirect_uri': 'https://www.sustainablife.online/googleAuthenticated',
    'response_type': 'token',
    'scope': 'https://www.googleapis.com/auth/drive.metadata.readonly',
    'include_granted_scopes': 'true',
    'state': 'pass-through_sustainablife_oAuth'
  };

  // Add form parameters as hidden input values.
  for (var p in params) {
    var input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', p);
    input.setAttribute('value', params[p]);
    form.appendChild(input);
  }

  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form);
  form.submit();
}

const storeUser = (oauthResult) => {
  var params = {};
  var regex = /([^&=]+)=([^&]*)/g, m;  
  while (m = regex.exec(oauthResult)) {
    params[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);    
  }
  if (Object.keys(params).length > 0) {
    localStorage.setItem('oauth2-google-sustainablife', JSON.stringify(params));
    if (params['state'] && params['state'] == 'pass-through_sustainablife_oAuth') {
      requestInfo();
    }    
  }  
}

const requestInfo = async () => {
  var params = JSON.parse(localStorage.getItem('oauth2-google-sustainablife'));
  console.log(params['access_token']);
  if (params && params['access_token']) {
    fetch("https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=" + params['access_token'], {
      method: 'GET',
    }).then((response) => {
      response.json().then((responseRes) => { 
        if(responseRes.verified_email){          
          googleSignup({email:responseRes.email, name:responseRes.given_name, surname:responseRes.family_name}).then((res) => {
            console.log('googleReg on Sustainablife RES:' + res);
          });          
        }
      });      
    }).catch((error) => {
      return error;
    });
  }
}

const signOut = () => {
  localStorage.removeItem('oauth2-google-sustainablife');
}

export { oauthSignIn, storeUser, requestInfo, signOut };

