import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';
import Modal from '../../../../components/Modal/Modal';
import { Back, Close, Timeout } from '../../../../components/icon/icons';
import ScreenTitle from '../../../../components/inappComponents/ScreenTitles/ScreenTitle';
import Button from '../../../../components/Button/Button';

import styles from "./programDetails.module.scss";
import stylesApp from "../../app.module.scss";
import { redirect } from 'react-router-dom';
import ToggleButton from '../../../../components/ToggleButton/ToggleButton';
import vars from "../../../../globalVars.module.scss";
import List from '../../../../components/List/List';
import ModalDialog from '../../../../components/ModalDialog/ModalDialog';
import Progress from '../../../../components/progress/Progress';
import { GlobalContext } from '../../../../handlers/GlobalContext';
import { getProgramDetails } from '../../../../restAPI/pickup';
import StackDetails from '../stackDetails/stackDetails';
import { PopupsContext } from '../../../../handlers/PopupsContext';
const { lightColorMain } = vars;

interface ProgramProps {
    pickupProgram: any;
    onClose?: () => void;
}

const ProgramDetails: FunctionComponent<ProgramProps> = (props) => {

    const { updateCategory, user, notifications } = useContext(GlobalContext);
    const { showAlertPopup, showModal, showBusy, hideBusy } = useContext(PopupsContext);

    const [showCancelAlert, setShowCancelAlert] = useState<boolean>(false);
    const [clickedHeader, setClickedHeader] = useState<string>('Consumer');
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [listConsumers, setListConsumers] = useState<any>([]);
    //     { 'image': 'Plastics', 'name': 'Pinar Gulshan', 'deliveryCode': '202320', 'pkUse': '4518' },
    //     { 'image': 'Plastics', 'name': 'Istanbul Saviour', 'deliveryCode': '1234', 'pkUse': '2020' },
    //     { 'image': 'Plastics', 'name': 'Mehmat Guardian', 'deliveryCode': '423665', 'pkUse': '1900' },
    //     { 'image': 'Plastics', 'name': 'Pinar Gulshan', 'deliveryCode': '542117', 'pkUse': '1560' },
    //     { 'image': 'Plastics', 'name': 'Istanbul Saviour', 'deliveryCode': '658879', 'pkUse': '9899' },
    //     { 'image': 'Plastics', 'name': 'Mehmat Guardian', 'deliveryCode': '908873', 'pkUse': '7652' },
    //     { 'image': 'Plastics', 'name': 'Pinar Gulshan', 'deliveryCode': '123456', 'pkUse': '9321' },
    //     { 'image': 'Plastics', 'name': 'Istanbul Saviour', 'deliveryCode': '654321', 'pkUse': '6789' },
    //     { 'image': 'Plastics', 'name': 'Mehmat Guardian', 'deliveryCode': '127809', 'pkUse': '2523' },
    //     { 'image': 'Plastics', 'name': 'Pinar Gulshan', 'deliveryCode': '889754', 'pkUse': '9098' },
    //     { 'image': 'Plastics', 'name': 'Istanbul Saviour', 'deliveryCode': '135798', 'pkUse': '1414' },
    //     { 'image': 'Plastics', 'name': 'Mehmat Guardian', 'deliveryCode': '123890', 'pkUse': '5783' },
    // ]);
    const dateOptions: any = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    const today = new Intl.DateTimeFormat(undefined, dateOptions).format(new Date());

    useEffect(() => {
        (async () => {
            showBusy('loading', '');
            let consumerList;
            if (user !== undefined){
                console.log('userId: ' + user!.accountType);
                consumerList = await getProgramDetails(props.pickupProgram.pkPickup, 0, 0);
            }

            if (consumerList !== undefined)
                setListConsumers(await JSON.parse(consumerList).result);
            hideBusy();
        })()
    }, [])

    const refreshHeaderClasses = (e:any) => {
        setClickedHeader(e.target.id);
    }


    if (showDetails)
        return (<Modal opacity={1}>
            <StackDetails stackId={selectedItem.fkStack} onClose={() => setShowDetails(false)} />
        </Modal>);
    else
    return (
        <div className={stylesApp.pageContainer}>
            <div className={stylesApp.pageHeaderContainer}>
                <div className={stylesApp.backContainer}>
                    <Back height={'64px'} onClick={() => { props.onClose!(); }} />
                </div>
                <ScreenTitle titles={['pickup', 'Program', '(details)']} width={'115px'} />
                <div className={stylesApp.emptyCell}></div>
            </div>
            <div className={styles.description}>
                <div className={styles.headerRow}>
                    <div className={styles.label}>stack:</div>
                    <div className={styles.stackData}>
                        <div className={styles.data}>{props.pickupProgram.displayable}</div>
                        <div className={styles.dataSmall}>(Fatih Istanbul)</div>
                    </div>
                </div>
                <div className={stylesApp.separator}></div>
                <div className={styles.headerRow}>
                    <div className={styles.label}>due date:</div>
                    <div className={styles.dataSmall}>{props.pickupProgram.pickupStart.split('T')[0]}</div>
                </div>                
            </div>
            <div className={stylesApp.separator}></div>
            <div className={stylesApp.separator}></div>
            <div className={stylesApp.listHeader}>
                <div id='headerConsumer' className={clickedHeader === 'headerConsumer' ? stylesApp.headerItemClicked : stylesApp.headerItem} onClick={(e) => {
                    refreshHeaderClasses(e);
                }}>Consumer</div>
                <div id='headerRedeems' className={clickedHeader === 'headerRedeems' ? stylesApp.headerItemClicked : stylesApp.headerItem}  onClick={(e) => {
                    refreshHeaderClasses(e);
                }}><Timeout height={'32px'}/>Redeems</div>
            </div>
            <div className={stylesApp.listHeaderSeparator}></div>
            <List>
                {
                    listConsumers.map((item: any, index: number) => {
                        return (
                            // <ListItem index={index} onClick={() => { setShowCancelAlert(true) }}>
                            //     {item}
                            // </ListItem>
                            <div className={styles.container}>
                                {(index > 0) && <div className={styles.separator}></div>}
                                <div className={styles.program} onClick={() => {
                                                setSelectedItem(item);
                                                setShowDetails(true);
                                            }}>
                                    <div className={styles.user}>
                                        <div className={styles.image}><img className={styles.image} src={require('../../../../assets/photos/dummyRemovable/dummy' + Math.floor(Math.random() * 4) + '.jpg')} /></div>
                                        <div className={stylesApp.splitter}></div>
                                        <div className={styles.name}>{item.consumerFullName}</div>
                                    </div>                                    
                                    <div className={styles.button} onClick={() => { setShowCancelAlert(true) }}>
                                        <div className={styles.deliveryCode}>DC: {item.deliveryCode}</div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </List>
            <div className={styles.footer}>
                <div className={stylesApp.listHeaderSeparator}></div>
                <div className={styles.info}>
                    <div className={styles.stackDetails}>
                        <div className={styles.detailRow}>
                            <div className={styles.label}>total:</div>
                            <div className={styles.data}>897 Kg</div>
                        </div>
                        <div className={styles.detailRow}>
                            <div className={styles.dataSmall}>first redeem in:</div>
                            <div className={styles.dataTiny}>1.4 hours</div>
                        </div>
                    </div>
                    <Button width='140px' height='60px' borderRadius='10px' text='withdraw' fontFamily='interBold' fontSize='18px' textColor='white' color={'linear-gradient(to right, rgba(0, 33, 33, 1), rgba(255, 4, 4, 1))'} />
                    {/* <div style={{width: '50px', height: '50px', border: '1px solid green'}}>
                        withdraw
                    </div> */}
                </div>
            </div>
            {showCancelAlert &&
                <ModalDialog title='alert.' body='In case of cancelling your program, you will lose 15 points!' okLabel='ok' onCancel={() => setShowCancelAlert(false)} />
            }
        </div>
    );
};

export default ProgramDetails;