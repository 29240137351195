import { FunctionComponent } from "react";
import { Settings } from "../assets/statics/Settings";
import { rejects } from "assert";

export interface pickup {

}

export const getPickupPrograms = async (
    userId: string,
    userType: 'consumer'|'collector',
    appointmentType: 'all'|'active',
    pageSize: number, 
    pageNumber: number): Promise<any | undefined> => {

    return new Promise((resolve, reject) => {
        try {
            fetch(Settings.api.path + "/pickup/programs/" + userType + '/' + appointmentType + '/' + pageSize + '/' + pageNumber, {
                method: 'GET',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'AuthenticationToken': 'cyrus#1@Cyrus@React',
                    'queryData': JSON.stringify({ 'userId': userId })
                })
            }).then((response) => {
                response.json().then((result) => {
                    resolve(JSON.stringify({ 'result': result.result }));
                }).catch((error) => {
                    resolve(undefined);
                });
            }).catch((error) => {                    
                reject(error);
            });
        }
        catch (error) {               
            reject(error);
        }
    });
}

export const getUserPickupCount = async (
    userId: string,
    userType: 'consumer'|'collector'): Promise<any | undefined> => {

    return new Promise((resolve, reject) => {
        try {
            fetch(Settings.api.path + "/pickup/count/" + userType, {
                method: 'GET',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'AuthenticationToken': 'cyrus#1@Cyrus@React',
                    'queryData': JSON.stringify({ 'userId': userId })
                })
            }).then((response) => {
                response.json().then((result) => {
                    resolve(JSON.stringify({ 'result': result.result }));
                }).catch((error) => {
                    resolve(error);
                });
            }).catch((error) => {                    
                reject(error);
            });
        }
        catch (error) {               
            reject(error);
        }
    });
}

export const getProgramDetails = async (
    programId: string,
    pageSize: number, 
    pageNumber: number): Promise<any | undefined> => {

    return new Promise((resolve, reject) => {
        try {
            fetch(Settings.api.path + "/pickup/programDetails/" + pageSize + '/' + pageNumber, {
                method: 'GET',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'AuthenticationToken': 'cyrus#1@Cyrus@React',
                    'queryData': JSON.stringify({ 'programId': programId })
                })
            }).then((response) => {
                response.json().then((result) => {
                    resolve(JSON.stringify({ 'result': result.result }));
                }).catch((error) => {
                    resolve(undefined);
                });
            }).catch((error) => {                    
                reject(error);
            });
        }
        catch (error) {               
            reject(error);
        }
    });
}
