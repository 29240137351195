import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./profile.module.scss";
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import stylesApp from "../app.module.scss";
import Modal from '../../../components/Modal/Modal';
import { Close, MapPin } from '../../../components/icon/icons';
import ScreenTitle from '../../../components/inappComponents/ScreenTitles/ScreenTitle';
import GroupBox from '../../../components/GroupBox/GroupBox';
import FormItem from '../../../components/FormItem/FormItem';
import Textbox from '../../../components/Textbox/Textbox';
import Button from '../../../components/Button/Button';

import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getCenterLatLong } from '../../../handlers/mapHelper';
import { getProfile, getProfilePhoto, updateProfile, updateProfilePhoto } from '../../../restAPI/user';
import { GlobalContext } from '../../../handlers/GlobalContext';
import Progress from '../../../components/progress/Progress';
import ModalDialog from '../../../components/ModalDialog/ModalDialog';
import NowasteMap from '../nowasteMap/nowasteMap';
import { PopupsContext } from '../../../handlers/PopupsContext';

let pos: any = undefined;

const render = (status: Status): ReactElement => {
    switch (status) {
        case Status.LOADING:
            return (<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', zIndex: '100', backgroundColor: 'green' }}>
                Loading ...
            </div>);
        case Status.FAILURE:
            return (<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', zIndex: '100', backgroundColor: 'red' }}>
                Error LOADING Google Maps!
            </div>);
        case Status.SUCCESS:
            return <NowasteMap center={pos !== undefined ? { lat: Number(pos.split(',')[0]), lng: Number(pos.split(',')[1]) } : { lat: 48.9932542, lng: 2.3489162 }} zoom={17.01} showAddressPin onClick={(value) => { pos = getCenterLatLong(value) }} />;
    }
};

interface ProfileProps {
    onClose?: () => void;
}

const Profile: FunctionComponent<ProfileProps> = (props) => {

    const { updateCategory, user, notifications } = useContext(GlobalContext);
    const { showAlertPopup, showModal, showBusy, hideBusy } = useContext(PopupsContext);
    
    const initialRef: any = null;
    const userPhotoFilePicker = useRef(initialRef);
        
    const [showBirthdayPicker, setShowBirthdayPicker] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [surname, setSurname] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [birthday, setBirthday] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [showPinAddress, setShowPinAddress] = useState<boolean>(false);
    
    const [photoUploading, setPhotoUploading] = useState<boolean>(false);
    const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false);
    const [alertDialogTitle, setAlertDialogTitle] = useState<string>('');
    const [alertDialogBody, setAlertDialogBody] = useState<string>('');
    
    const [userLevel, setUserLevel] = useState<string>('savior');
    const [userPhoto, setUserPhoto] = useState<string>(require('../../../assets/photos/Savior Dummy.jpg'));
    
    const dateOptions: any = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    const today = new Intl.DateTimeFormat(undefined, dateOptions).format(new Date());
    
    useEffect(() => {
        (async () => {
            showBusy('loading', '');
            if (user !== undefined) {
            try{
                const photo = await getProfilePhoto(user.pkUser);
                if (photo !== undefined)
                    setUserPhoto('data:' + photo.MIMEType + ';base64,' + photo.base64);

                const profileInfo = await getProfile(user.pkUser);
                if (profileInfo !== undefined) {
                    setEmail(profileInfo.username!);
                    setName(profileInfo.name!);
                    setSurname(profileInfo.surname!);
                    setPhone(profileInfo.phone!);
                    setAddress(profileInfo.address!);
                    pos = profileInfo.location!;                    
                }
            }catch(error){
                console.log('profile loading: ' + error);
            }
            }
            hideBusy();
        })()
    }, [user])

    const getLevelBadge = () => {
        switch (userLevel) {
            case 'planter':
                return require('../../../assets/photos/Planter.png');
            case 'forester':
                return require('../../../assets/photos/Forester.png');
            case 'guardian':
                return require('../../../assets/photos/Guardian.png');
            case 'savior':
            default:
                return require('../../../assets/photos/Savior Cover.png');
        }
    }

    const showAlert = (title: string, body: string) => {
        setShowAlertDialog(true);
        setAlertDialogTitle(title);
        setAlertDialogBody(body);
    }

    const updateUserPhoto = (dataUri: any) => {
        setPhotoUploading(true);
        if (dataUri !== null) {
            const userPhoto = URL.createObjectURL(dataUri);

            console.log(dataUri);
            setUserPhoto(userPhoto);

            const reader = new FileReader();
            reader.onloadend = async () => {
                try {
                    const base64Image = reader.result!.toString().split(',')[1];
                    const result = await updateProfilePhoto(user!.pkUser, base64Image, dataUri);

                    showAlert('confirm.', 'Photo updated successfully!');
                    setShowAlertDialog(true);
                    setPhotoUploading(false);
                }
                catch (error) {
                    showAlert('failure.', 'error uploading photo: ' + error);
                    setPhotoUploading(false);
                }
            }
            reader.readAsDataURL(dataUri);
        }
    }

    const saveChanges = async () => {
        try {
            showBusy('loading', '');
            const res = await updateProfile(user!.pkUser, name, surname, phone, address, pos);
            if (res.UserID === user.pkUser) {                
                document.location = '/app';
            }
        }
        catch (error) {
            showAlert('failure.', 'error saving changes: ' + error);
        }
        hideBusy();
    }


        return (
            <Modal color='#D9D9D9' zIndex='1000'>
                
                {showAlertDialog &&
                    //ToDO replace with wrapper modaldialog
                    <ModalDialog onCancel={() => setShowAlertDialog(false)} title={alertDialogTitle} body={alertDialogBody} />
                }
                <div className={stylesApp.screenContainer}>
                    <div className={stylesApp.screenHeaderContainer}>
                        <div className={stylesApp.closeContainer}>
                            <Close height={'64px'} onClick={() => {
                                document.location = '/app';
                            }} />
                        </div>
                        <ScreenTitle titles={['savior', 'Profile']} width={'95px'} />
                    </div>
                    <GroupBox width={'85%'} height={'fit-content'} title={'profile info.'}>
                        <div>
                            <div className={styles.profilePhoto} onClick={() => userPhotoFilePicker.current!.click()} >
                                {photoUploading &&
                                    <Progress />
                                }
                                {userPhoto &&
                                    <img src={userPhoto} style={{ borderRadius: '50%' }} width={'128px'}></img>
                                }
                                {userLevel &&
                                    <img className={styles.badgeHolder} width={(userLevel === 'savior' || userLevel === undefined) ? '128px' : '48px'} src={getLevelBadge()} />
                                }
                                <input
                                    type="file"
                                    id='userPhotoFileId'
                                    name='userPhotoFileName'
                                    accept="image/jpg, image/png, image/bmp, image/jpeg, image/webp"
                                    ref={userPhotoFilePicker}
                                    style={{ display: 'none' }}
                                    onChange={(event) => {
                                        if (event.target.files !== null) {
                                            updateUserPhoto(event.target.files[0]);
                                        }
                                    }}
                                />
                            </div>
                            <div style={{ fontFamily: 'copperPlateGothicBoldRegular', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>SAVIOR</div>
                        </div>
                        <div className={stylesApp.separator}></div>
                        <FormItem title={'name'} errorMessage={''}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'241px'} height={'42px'} placeHolder={'name'} text={name} onChange={(value) => { if (value !== name) setName(value) }} />
                            </div>
                        </FormItem>
                        <FormItem title={'surname'} errorMessage={''}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'241px'} height={'42px'} placeHolder={'surname'} text={surname} onChange={(value) => { if (value !== surname) setSurname(value) }} />
                            </div>
                        </FormItem>
                        <FormItem title={'email'} errorMessage={''}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'241px'} height={'42px'} placeHolder={'email'} readOnly text={email} onChange={(value) => { if (value !== email) setEmail(value) }} />
                            </div>
                        </FormItem>
                        <FormItem title={'phone'} errorMessage={''}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'241px'} height={'42px'} placeHolder={'phone'} text={phone} onChange={(value) => { if (value !== phone) setPhone(value) }} />
                            </div>
                        </FormItem>
                        {/* <FormItem title={'birthday'} errorMessage={''}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'241px'} height={'42px'} placeHolder={'birthday'} readOnly onClick={() => setShowBirthdayPicker(true)} onChange={(value) => { setBirthday(value) }} />
                            </div>
                        </FormItem> */}
                        <FormItem title={'address'} errorMessage={''}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', position: 'relative', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox type='multiline' width={'241px'} height={'84px'} placeHolder={'address'} text={address} onChange={(value) => { if (value !== address) setAddress(value) }} />
                                <div style={{ height: '32px', width: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', right: '5px', top: '5px' }} onClick={() => { setShowPinAddress(true) }}>
                                    <MapPin height={'100%'} />
                                </div>
                            </div>
                        </FormItem>
                        <div className={stylesApp.separator}></div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', position: 'absolute', bottom: '-21px', opacity: 1 }}>
                            <Button width='170px' height='42px' text='save' color='#002121' textColor='#FFFFFF' fontFamily='interBold' fontSize='18px' onClick={() => saveChanges()} />
                        </div>
                    </GroupBox>
                    <div className={stylesApp.separator}></div>
                </div>
                {showBirthdayPicker &&
                    <Modal>
                        <div className={stylesApp.timePicker}>
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateCalendar onChange={(value) => {
                                        setBirthday(value.$y + '/' + value.$M + '/' + value.$D);
                                    }}></DateCalendar>
                                </LocalizationProvider>
                            </div>
                            <div className={stylesApp.mixedDateControls}>
                                <div className={styles.splitter}></div>
                                <Button width='130px' height='42px' text='cancel' borderColor='#002121' textColor='#002121' fontFamily='interBold' fontSize='18px' onClick={() => setShowBirthdayPicker(false)} />
                                <div className={styles.splitter}></div>
                                <Button width='130px' height='42px' text='set' borderColor='#002121' textColor='#002121' fontFamily='interBold' fontSize='18px' onClick={() => setShowBirthdayPicker(false)} />
                                <div className={styles.splitter}></div>
                            </div>
                        </div>
                    </Modal>
                }
                {showPinAddress &&
                    <Modal opacity={1}>
                        <div className={stylesApp.screenContainerLousy}>
                            <div className={stylesApp.screenHeaderContainerLousy}>
                                <div className={stylesApp.closeContainerLousy}>
                                    <Close height={'64px'} onClick={() => {
                                        setShowPinAddress(false);
                                    }} />
                                </div>
                                <ScreenTitle titles={['savior', 'Location']} width={'115px'} />
                            </div>
                            <Wrapper apiKey={"AIzaSyDHy-raustGotVuofGKl9VuTtkmo7ZUuXE"} render={render} />
                            <div style={{ position: 'absolute', zIndex: '1000', bottom: '50px' }}>
                                <Button text='confirm' onClick={() => setShowPinAddress(false)} />
                            </div>
                        </div>
                    </Modal>
                }
            </Modal>
        );
};

export default Profile;