import { FunctionComponent, useEffect, useRef, useState } from "react";
import { GlobalContext } from "./GlobalContext";
import AlertPopup from "../components/alertPopup/AlertPopup";
import ModalDialog from "../components/ModalDialog/ModalDialog";
import Busy from "../components/loading/Busy";
import { getNotifications } from "../restAPI/notifications";

const GlobalWrapper: FunctionComponent<any> = (props) => {

    const [user, setUser] = useState<any>();
    const [selectedCategory, setSelectedCategory] = useState<string | undefined>();
    
    const [notifications, setNotifications] = useState<any>();
    
    // const [alertMessage, setAlertMessage] = useState<string>('');
    // const [showAlert, setShowAlert] = useState<boolean>(false);
    // const [alertDuration, setAlertDuration] = useState<number>(1000);
    // const [alertType, setAlertType] = useState<'error'|'info'|'message'|'question'>('info');
    
    // const [modalTitle, setModalTitle] = useState<string>('');
    // const [modalMessage, setModalMessage] = useState<string>('');
    // const [modalButton, setModalButton] = useState<string>('');
    // const [showModal, setShowModal] = useState<boolean>(false);
    // const refModalOk = useRef<any>(null);
    // const refModalCancel = useRef<any>(null);

    // const [busyMessage, setBusytMessage] = useState<string>('');
    // const [showBusy, setShowBusy] = useState<boolean>(false);
    // const [busyType, setBusyType] = useState<'searching'|'saving'|'loading'|'busy'>('busy');

    useEffect(() => {
        let intervalNotifications:any;
        (async () => {
            const userAccount = localStorage.getItem('activeAccount');
            const accountData = await JSON.parse(userAccount!);
            setUser(accountData!);            
            if(accountData!){
                notificationLoader(accountData!.pkUser, accountData!.accountType);
                intervalNotifications = setInterval(notificationLoader
                //     async () => {
                //     console.log(`interval ${intervalNotifications} + ` + Date());
                //     setNotifications(await getNotifications(accountData!.pkUser, accountData!.accountType));
                // }
                , 30000, accountData!.pkUser, accountData!.accountType);
            }
        })()
        return () => {
            clearInterval(intervalNotifications);
        }
    }, [])

    const notificationLoader = async(userId:string, userType:string) => {
        //console.log(`interval ${intervalNotifications} + ` + Date());
        setNotifications(await getNotifications(userId, userType));
    }    

    // // useEffect(() => {
    // //     (async () => {
    // //         // const userAccount = localStorage.getItem('activeAccount');
    // //         // const accountData = JSON.parse(userAccount!);
    // //         // return accountData === null ? undefined : accountData!.pkUser;
    // //     })()
    // // }, [showAlert])

    // const clearAlertPopup = () => {
    //     setAlertMessage('');
    //     setAlertType('info');
    //     setAlertDuration(1000);
    //     setShowAlert(false);        
    // }

    return (
        <GlobalContext.Provider value={{
            //user authentication
            user,
            displayingCategory: selectedCategory,
            updateCategory: (newValue: string) => {
                setSelectedCategory(newValue);
            },
            notifications,
        
        //     //alerts' popup
        //     alertMessage,
        //     alertDuration,
        //     alertType: 'info',
        //     showAlertPopup: (message: string, type: 'error' | 'info' | 'message' | 'question', duration: number) => {
        //         setAlertMessage(message);
        //         setAlertType(type);
        //         setAlertDuration(duration);
        //         setShowAlert(true);
        //     },
        //     hideAlertPopup: () => {
        //         clearAlertPopup();
        //     },
        //     //modal dialog
        //     modalTitle,
        //     modalMessage,
        //     showModal: (title: string, message: string, confirmButton: string, onOk: () => void, onCancel: () => void) => {
        //         setModalTitle(title);
        //         setModalMessage(message);
        //         setModalButton(confirmButton);
        //         setShowModal(true);
        //         refModalOk.current = onOk;
        //         refModalCancel.current = onCancel;
        //     },
        //     //busy
        //     busyType,
        //     busyMessage,
        //     showBusy: (type: 'searching' | 'saving' | 'loading' | 'busy', message: string) => {
        //         setBusyType(type);
        //         setBusytMessage(message);
        //         setShowBusy(true);
        //     },
        //     hideBusy: () => {
        //         setShowBusy(false);
        //     }
        // }}>
        //     {showBusy &&
        //         <Busy message={busyMessage} type={busyType} />
        //     }
        //     {showAlert &&
        //         <AlertPopup message={alertMessage} type={alertType} timerLength={alertDuration} onTimeout={() => { clearAlertPopup() }} />
        //     }
        //     {showModal &&
        //         <ModalDialog title={modalTitle} body={modalMessage} okLabel={modalButton}
        //             onCancel={() => {
        //                 setShowModal(false);
        //                 refModalCancel.current();
        //             }}
        //             onOk={() => {
        //                 setShowModal(false);
        //                 refModalOk.current();
        //             }}
        //         />
        //     }
    }}>
            {props.children}
        </GlobalContext.Provider>
    )
}

export default GlobalWrapper;