import { FunctionComponent } from "react";
import { Settings } from "../assets/statics/Settings";

export interface user {
    uId: string | null;
    fkUser: string;
    fkCategory: string;
    volume: number;
    fkUnit: string;
    redeemsAt: string;
    deliveryCode: string;
}

export const recordStack = async (
    fkContact: string,
    selectedCategory: string,
    volume: number,
    redeemsAt: string): Promise<any | undefined> => {

    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/stack", {
            method: 'PUT',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React'
            }),
            body: JSON.stringify({
                fkContact: fkContact,
                fkCategory: selectedCategory,
                volume,
                redeemsAt,
            })
        }).then((response) => {
            response.json().then((result) => {
                console.log('-----==={ ' + result.result);
                resolve(result.result);
            }).catch((error) => { resolve(false) });
        }).catch((error) => {
            resolve(false);
        });
    });
}

export const recordStackPhoto = async (
    pkStackPhoto: string | undefined,
    fkStack: string | undefined,
    fkUserTemp: string | undefined,
    photo: string,
    fileObject: any, indexInternal: number): Promise<any | undefined> => {

    return new Promise((resolve, reject) => {
        console.log('for USER: ' + fkUserTemp);
        fetch(Settings.api.path + "/stack/photo", {
            method: 'PUT',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React'
            }),
            body: JSON.stringify({
                pkStackPhoto,
                fkStack,
                filename: fileObject.name,
                MIMEType: fileObject.type,
                size: fileObject.size,
                base64: photo,
                fkUserTemp
            })
        }).then((response) => {
            response.json().then((result) => {
                resolve(JSON.stringify({'index': indexInternal, 'result': result.result}));
            }).catch((error) => {
                reject(JSON.stringify({'index': indexInternal, 'result': undefined})) });
        }).catch((error) => {
            reject(JSON.stringify({'index': indexInternal, 'result': undefined}));
        });
    });
}

export const removeTempStackPhoto = async (
    pkStackPhoto: string, 
    fkUserID: string): Promise<any | undefined> => {

    return new Promise((resolve, reject) => {
        
        fetch(Settings.api.path + "/stack/photo", {
            method: 'DELETE',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React'
            }),
            body: JSON.stringify({
                pkStackPhoto, 
                fkUserID
            })
        }).then((response) => {            
            response.json().then((result) => {                
                resolve(result.result);
            }).catch((error) => {                 
                reject(error);
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

export const removeTempStackPhotos = async (
    fkUserID: string): Promise<any | undefined> => {

    return new Promise((resolve, reject) => {
        
        fetch(Settings.api.path + "/stack/photos", {
            method: 'DELETE',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React'
            }),
            body: JSON.stringify({
                fkUserID
            })
        }).then((response) => {            
            response.json().then((result) => {                
                resolve(result.result);
            }).catch((error) => {                 
                reject(undefined);
            });
        }).catch((error) => {
            reject(undefined);
        });
    });
}