import React, { Children, FunctionComponent, useEffect, useState } from "react";
import styles from "./modal.module.scss";
import { inherits } from "util";

interface ModalProps {
    fontFamily?: string;
    fontSize?: string;
    placeHolder?: string;
    textColor?: string;
    color?: string;
    borderColor?: string;
    disabledColor?: string;    
    disabled?: boolean;
    width?: string;
    height?: string;
    onClick?: (value: any) => void
    onChange?: (value: any) => void;
    children?: React.ReactNode; 
    opacity?: number;
    zIndex?: string;
    glassEffect?: boolean|false;
}

const Modal: FunctionComponent<ModalProps> = (props) => {
    return (
        <div className={props.glassEffect ? styles.modalContainerGlassy : styles.modalContainer} 
            style={{ 
                fontFamily: props.fontFamily!, fontSize: props.fontSize!, 
                width: props.width!,
                height: props.height!,
                backgroundColor: props.glassEffect ? 'unset' : (!props.disabled ? props.color! : props.disabledColor!), 
                borderColor: !props.disabled ? props.borderColor! : props.disabledColor!, 
                opacity: props.opacity,
                zIndex: props.zIndex ? props.zIndex! : '10000',
            }}
            onClick={() => {
                if (!props.disabled && props.onClick)
                    props.onClick(false);
            }}>
            {props.children}
        </div>
    );
}

export default Modal;