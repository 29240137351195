import {createContext, useState} from "react";
import content from "../assets/statics/Language.json";

interface GlobalContextProps {
    //user authentication
    user: any|undefined,
    displayingCategory: string|undefined,
    updateCategory:(newCategory:string) => void,

    notifications: any|undefined,

    // //alerts' popup
    // alertMessage: String,
    // alertDuration: number,
    // alertType: 'error'|'info'|'message'|'question',
    // showAlertPopup:(message:string, type:'error'|'info'|'message'|'question', duration:number)=>void,
    // hideAlertPopup:()=>void

    // //modal dialog
    // modalTitle: string,
    // modalMessage: string,
    // showModal:(title:string, message:string, confirmButton:string, onOk:()=>void, onCancel:()=>void)=>void,          

    // //busy screen
    // busyType: 'searching'|'saving'|'loading'|'busy',
    // busyMessage: string,
    // showBusy: (type: 'searching'|'saving'|'loading'|'busy', message: string) => void,
    // hideBusy: () => void
}

export const GlobalContext = createContext<GlobalContextProps>({
    //user authentication
    user:undefined,
    displayingCategory:undefined,
    updateCategory: (value:string)=>{},

    notifications: undefined,

    // //alerts' popup
    // alertMessage: '',
    // alertDuration: 3000,
    // alertType: 'info',
    // showAlertPopup:(message:string, type:'error'|'info'|'message'|'question', duration:number)=>{},
    // hideAlertPopup:()=>{},

    // //modal dialog
    // modalTitle: '',
    // modalMessage: '',
    // showModal:(title:string, message:string, confirmButton:string, onOk:()=>void, onCancel:()=>void)=>{},

    // //busy screen
    // busyType: 'busy',
    // busyMessage: '',
    // showBusy: (type: 'searching' | 'saving' | 'loading' | 'busy', message: string) => { },
    // hideBusy: () => { }
});