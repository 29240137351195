import { FunctionComponent, useEffect, useRef, useState } from "react";
import AlertPopup from "../components/alertPopup/AlertPopup";
import ModalDialog from "../components/ModalDialog/ModalDialog";
import Busy from "../components/loading/Busy";
import { PopupsContext } from "./PopupsContext";
import InfoPopup from "../components/infoPopup/InfoPopup";

const PopupsWrapper: FunctionComponent<any> = (props) => {
        
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [alertDuration, setAlertDuration] = useState<number>(1000);
    const [alertType, setAlertType] = useState<'error'|'info'|'message'|'question'>('info');

    const [infoTitle, setInfoTitle] = useState<string>('');
    const [infoBody, setInfoBody] = useState<string>('');    
    const [showInfo, setShowInfo] = useState<boolean>(false);

    const [modalTitle, setModalTitle] = useState<string>('');
    const [modalMessage, setModalMessage] = useState<string>('');
    const [modalButton, setModalButton] = useState<string>('');
    const [showModal, setShowModal] = useState<boolean>(false);
    const refModalOk = useRef<any>(null);
    const refModalCancel = useRef<any>(null);

    const [busyMessage, setBusytMessage] = useState<string>('');
    const [showBusy, setShowBusy] = useState<boolean>(false);
    const [busyType, setBusyType] = useState<'searching'|'saving'|'loading'|'busy'>('busy');

    useEffect(() => {
        
        (async () => {})()        
    }, [])

    const clearAlertPopup = () => {
        setAlertMessage('');
        setAlertType('info');
        setAlertDuration(1000);
        setShowAlert(false);        
    }

    return (
        <PopupsContext.Provider value={{
            //alerts' popup
            alertMessage,
            alertDuration,
            alertType: 'info',
            showAlertPopup: (message: string, type: 'error' | 'info' | 'message' | 'question', duration: number) => {
                setAlertMessage(message);
                setAlertType(type);
                setAlertDuration(duration);
                setShowAlert(true);
            },
            hideAlertPopup: () => {
                clearAlertPopup();
            },
            //infos' popup
            infoTitle,
            infoBody,
            showInfoPopup: (title: string, body: string) => {
                setInfoTitle(title);
                setInfoBody(body);
                setShowInfo(true);
            },
            //modal dialog
            modalTitle,
            modalMessage,
            showModal: (title: string, message: string, confirmButton: string, onOk: () => void, onCancel: () => void) => {
                setModalTitle(title);
                setModalMessage(message);
                setModalButton(confirmButton);
                setShowModal(true);
                refModalOk.current = onOk;
                refModalCancel.current = onCancel;
            },
            //busy
            busyType,
            busyMessage,
            showBusy: (type: 'searching' | 'saving' | 'loading' | 'busy', message: string) => {
                setBusyType(type);
                setBusytMessage(message);
                setShowBusy(true);
            },
            hideBusy: () => {
                setShowBusy(false);
            }
        }}>
            {showBusy &&
                <Busy message={busyMessage} type={busyType} />
            }
            {showInfo &&
                <InfoPopup title={infoTitle} body={infoBody} 
                    onOk={() => {
                        setShowInfo(false);
                    }} />
            }
            {showAlert &&
                <AlertPopup message={alertMessage} type={alertType} timerLength={alertDuration} onTimeout={() => { clearAlertPopup() }} />
            }
            {showModal &&
                <ModalDialog title={modalTitle} body={modalMessage} okLabel={modalButton}
                    onCancel={() => {
                        setShowModal(false);
                        refModalCancel.current();
                    }}
                    onOk={() => {
                        setShowModal(false);
                        refModalOk.current();
                    }}
                />
            }
            {props.children}
        </PopupsContext.Provider>
    )
}

export default PopupsWrapper;