import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./toggleButton.module.scss";

interface ToggleButtonProps {
    on?: boolean;
    leftText?: string;
    rightText?: string;
    displayTexts?: 'wings'|'left'|'right';
    selectedTextColor?: string,
    deselectedTextColor?: string,
    fontFamily?: string
    fontSize?: string;
    frameColor?: string;    
    buttonColor?: string;
    switchedOffColor?: string;
    switchedOnColor?: string;
    height?: number;
    image?: string;
    imageHeight?: string;
    imageWidth?: string;
    onClick?: (value:boolean)=>void
}

const ToggleButton: FunctionComponent<ToggleButtonProps> = (props) => {

    const [toggleOn, setToggleOn] = useState(props.on??false);
    const [leftText, setLeftText] = useState(props.leftText??undefined);
    const [rightText, setRightText] = useState(props.rightText??undefined);    

    useEffect(() => {
        console.log("TOGGLE " + props.on!);

        if(props.displayTexts === 'left'){
            setLeftText(toggleOn ? props.leftText : props.rightText);
            setRightText(undefined);
        }
        if(props.displayTexts === 'right'){
            setRightText(toggleOn ? props.leftText : props.rightText);
            setLeftText(undefined);
        }
        if(props.displayTexts === 'wings'){
            setRightText(props.rightText);
            setLeftText(props.leftText);
        }

    }, [toggleOn])

    return (
        <div className={styles.toggleContainer} style={{ fontFamily: props.fontFamily!}}>
            <div className={styles.toggleText} 
                style={{ fontSize: props.fontSize, fontFamily: props.fontFamily!, 
                    color: toggleOn ? props.selectedTextColor??"darkgrey" : props.deselectedTextColor??"darkgrey", 
                    padding: leftText! ? '7px' : '0px'}}>{leftText}</div>
            <div className={styles.toggleFrame} 
                style={{ borderColor: props.frameColor?? "darkgray",
                        backgroundColor: toggleOn ? props.switchedOnColor??"whitesmoke" : props.switchedOffColor??"lightgrey",
                        height: props.height ?? "50px",
                        width: props.height ? (props.height * 2) : "100px",
                        borderRadius: props.height ?? "50px",
                        borderWidth: props.height ? props.height / 10 : "5px",
                }} 
                onClick={(e) => {                    
                    setToggleOn(!toggleOn);                    
                    if(props.onClick)
                        props.onClick(toggleOn ? true : false);
            }}>
                <div className={styles.toggleCircle} style={{ height: props.height ?? "50px", width: props.height ?? "50px", left: toggleOn ? "0px" : "", right: !toggleOn ? "0px" : "", borderColor: props.buttonColor?props.buttonColor:'grey', backgroundColor: props.buttonColor?props.buttonColor:'grey' }}>
                    {props.image && <img src={props.image} width={props.imageWidth} height={props.imageHeight}/>}
                </div>
            </div>
            <div className={styles.toggleText} 
                style={{ fontSize: props.fontSize, 
                    color: !toggleOn ? props.selectedTextColor??"darkgrey" : props.deselectedTextColor??"darkgrey",
                    padding: rightText! ? '7px' : '0px'
                    }}>{rightText}</div>
        </div>
    );
}

export default ToggleButton;