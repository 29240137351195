import React, { FunctionComponent, ReactElement, createElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./landing.module.scss";
import stylesApp from "../app.module.scss";
import Modal from '../../../components/Modal/Modal';
import ScreenTitle from '../../../components/inappComponents/ScreenTitles/ScreenTitle';
import { Close } from '../../../components/icon/icons';
import { useSwipeable } from 'react-swipeable';

interface LandingProps {
    onClose?: () => void;
}

const Landing: FunctionComponent<LandingProps> = (props) => {

    const activePage = useRef<number>(0);

    const [footer, setFooter] = useState<string>('');

    const slides = [
        `<img src=${require('../../../assets/photos/landing/page01.png')} width='80%' />`,
        `<img src=${require('../../../assets/photos/landing/page02.png')} width='80%' />`,
        `<img src=${require('../../../assets/photos/landing/page03.png')} width='80%' />`,
        `<img src=${require('../../../assets/photos/landing/page04.png')} width='80%' />`,
        `<img src=${require('../../../assets/photos/landing/page05.png')} width='80%' />`,
    ];

    const swipeHandler = useSwipeable({
        onSwipedLeft: (event) => {
            handleSwipeLeft();
        },
        onSwipedRight: (event) => {
            handleSwipeRight();
        }
    });

    useEffect(() => {
        (async () => {
            renderFooter();
        })()
    }, [])

    useEffect(() => {
        (async () => {
            renderSwipe();
        })()
    }, [footer])

    const handleSwipeLeft = () => {
        activePage.current = activePage.current >= 4 ? 0 : activePage.current + 1;
        renderFooter();
    }

    const handleSwipeRight = () => {
        activePage.current = activePage.current <= 0 ? 4 : activePage.current - 1;
        renderFooter();
    }

    const renderSwipe = () => {
        if (document.getElementById('swipeablesDiv') !== null)
            document.getElementById('swipeablesDiv')!.innerHTML = slides[activePage.current];
        if (document.getElementById('footerDiv') !== null)
            document.getElementById('footerDiv')!.innerHTML = footer;
    }

    const renderFooter = () => {
        let footerInside = '';
        for (let cnt = 0; cnt <= 4; cnt++) {
            console.log(activePage.current + ' ' + cnt);
            footerInside += activePage.current === cnt ? `<div class=${styles.activeSwipe}></div>` : `<div class=${styles.swipe}></div>`;
        }
        setFooter(footerInside);
    }

    return (
        <Modal opacity={1}>
            <div className={stylesApp.screenContainer}>
                <div className={stylesApp.screenHeaderContainer}>
                    <div className={stylesApp.closeContainer}>
                        <Close height={'64px'} onClick={() => {
                            sessionStorage.setItem('landed', 'true');
                            props.onClose!();
                        }} />
                    </div>
                    <ScreenTitle titles={['a recycling', 'tournament']} width={'165px'} />
                </div>
                <div className={styles.separator}></div>
                <div className={styles.swipeContainer}>                    
                    <div id='swipeablesDiv' {...swipeHandler} className={styles.slideContainer}>
                    </div>
                    {footer &&
                        <div className={styles.choices}>
                            <div className={styles.sides}></div>
                            <div id='footerDiv' className={styles.swipeableFooter}>
                            </div>
                            <div className={styles.sides} onClick={() => {
                                if (activePage.current === 4)
                                    props.onClose!();
                                else
                                    handleSwipeLeft();
                            }}>skip</div>
                        </div>
                    }
                    <div >
                        <div className={styles.checkLanded} onClick={() => {
                            sessionStorage.setItem('landed', 'true');
                        }}>
                            <input type='checkbox' />do not show again
                        </div>

                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default Landing;