import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import styles from "./busy.module.scss";

interface BusyProps {
    fontFamily?: string;
    fontSize?: string;
    message: string;
    textColor?: string;
    color?: string;
    borderColor?: string;    
    width?: string;
    height?: string;
    type: 'searching'|'saving'|'loading'|'busy';
    children?: React.ReactNode; 
    opacity?: number;
}

const Busy: FunctionComponent<BusyProps> = (props) => {

    const intervalTimer = useRef<NodeJS.Timer>();
    const busyIcon = (() => {
        switch(props.type){
            case 'searching':
                return require('../../assets/animated/busySearching.png');
            case 'saving':
                return require('../../assets/animated/busySaving.png');
            case 'loading':
                return require('../../assets/animated/busyLoading.png');
            case 'busy':
                return require('../../assets/animated/progress.png');
            default:
                return require('../../assets/animated/progress.png');
        }
    });
     
    useEffect(() => {        
    }, [])

    return (
        <div className={styles.modalContainer} 
            style={{ fontFamily: props.fontFamily!, fontSize: props.fontSize!, 
                width: props.width!,
                height: props.height!,                
            }}
            onClick={() => {
            }}>
            <div className={styles.loadingContainer} >
                <div className={styles.imageContainer}>
                    <img src={busyIcon()} width='128px' />
                </div>
                {props.children}
                <div className={styles.message}>
                    {props.message}
                </div>
            </div>
        </div>
    );
}

export default Busy;