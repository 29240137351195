import React, { FunctionComponent, createRef, useEffect, useState } from "react";
import styles from "./textbox.module.scss";
import { inherits } from "util";

interface TextBoxProps {
    fontFamily?: string;
    fontSize?: string;
    placeHolder?: string;
    textColor?: string;
    color?: string;
    borderColor?: string;
    disabledColor?: string;
    disabled?: boolean;
    readOnly?: boolean;
    width?: string;
    height?: string;
    type?: string;
    text?: string;
    onClick?: (value: any) => void
    onChange?: (value: any) => void;
}

const Textbox: FunctionComponent<TextBoxProps> = (props) => {

    return (
        <div className={styles.textBoxContainer}
            style={{
                fontFamily: props.fontFamily!, fontSize: props.fontSize!,
                width: props.width!,
                height: props.height!,
                backgroundColor: !props.disabled ? props.color! : props.disabledColor!,
                borderColor: !props.disabled ? props.borderColor! : props.disabledColor!,
            }}
            onClick={(e) => {
                if (props.onClick)
                    props.onClick(e);
            }}>
            {props.type !== 'multiline' ?
                <input type={props.type} autoComplete="one-time-code" value={props.text!} readOnly={props.readOnly!}
                    
                    style={{
                        padding: '0px 10px',
                        width: '100%', height: '100%',
                        backgroundColor: "transparent", borderColor: "transparent",
                        fontFamily: props.fontFamily! ?? 'inherit',
                        fontSize: props.fontSize! ?? 'inherit',
                        color: props.color!
                    }}
                    placeholder={props.placeHolder!}
                    onChange={(e) => {
                        if (props.onChange)
                            props.onChange(e.target.value);
                    }}
                />
                :
                <textarea autoComplete="one-time-code" value={props.text!} readOnly={props.readOnly!}                    
                    rows={5}
                    style={{
                        padding: '0px 10px',
                        width: '100%', height: '100%',
                        backgroundColor: "transparent", 
                        borderColor: "transparent",
                        fontFamily: props.fontFamily! ?? 'inherit',
                        fontSize: props.fontSize! ?? 'inherit',
                        color: props.color!                        
                    }}
                    placeholder={props.placeHolder!}
                    onChange={(e) => {
                        if (props.onChange)
                            props.onChange(e.target.value);
                    }}
                />
            }
        </div>
    );
}

export default Textbox;