import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./appSignup.module.scss";
import stylesApp from "../app.module.scss";
import Modal from '../../../components/Modal/Modal';
import { Close } from '../../../components/icon/icons';
import ScreenTitle from '../../../components/inappComponents/ScreenTitles/ScreenTitle';
import GroupBox from '../../../components/GroupBox/GroupBox';
import FormItem from '../../../components/FormItem/FormItem';
import Textbox from '../../../components/Textbox/Textbox';
import Button from '../../../components/Button/Button';
import { GoogleSingin } from '../../../components/icon/icons';
import { oauthSignIn } from '../../../handlers/googleSignIn';

import ToggleButton from '../../../components/ToggleButton/ToggleButton';
import { Link } from 'react-router-dom';
import { signupUser } from '../../../restAPI/user';
import vars from "../../../globalVars.module.scss";
const { lightColorMain, controlColor, darkColorApp, darkColorMenu, lightColorMenu } = vars;

interface AppSignupProps {

    onClose?: () => void;
}

const AppSignup: FunctionComponent<AppSignupProps> = (props) => {

    const textbox = useRef();

    const [loading, setLoading] = useState<boolean>(false);
    const [invalidDate, setInvalidDate] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordRetypr, setPasswordRetype] = useState<string>('');
    const [accountType, setAccountType] = useState<string>();
    
    const dateOptions: any = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    const today = new Intl.DateTimeFormat(undefined, dateOptions).format(new Date());

    useEffect(() => {
        (async () => {
            const a = "";

            setLoading(true);
            // const unitList = await getUnits();
            // setUnits(JSON.parse(JSON.stringify(unitList!.unitList).replaceAll('pkUnit', 'value').replaceAll('displayable', 'label')));
            // const categoryList = await getCategories();
            // setCategories(JSON.parse(JSON.stringify(categoryList!.categoryList.sort((a:any, b:any) => { return (a.displayable > b.displayable ? 1 : -1)  })).replaceAll('pkCategory', 'value').replaceAll('displayable', 'label')));
            setLoading(false);

            const script = document.createElement("script");
            script.src = "https://accounts.google.com/gsi/client";
            script.async = true;
            document.body.appendChild(script);
        })()
    }, [])

    const signup = async () => {
        setLoading(true);
        const signupResult = await signupUser(email, password);
        if(signupResult !== undefined)
            alert('Your account created please check your email to confirm!');
        console.log(signupResult);
        setLoading(false);
    }

    


    const googleSignup = () => {
        oauthSignIn();
    }

    const validate = () => {
        setInvalidDate('*invalid date');
    }

    if (loading)
        return (<Modal>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <img src={require('../../../assets/animated/progress.png')} width={'128px'}></img>
                <div style={{ color: '#7D9B78', padding: '15px', fontFamily: 'interExtraBold', fontSize: '28px', letterSpacing: '5px' }}>Loading...</div>
            </div>
        </Modal>);
    else
        return (
            <Modal color='#D9D9D9'>
                <div className={stylesApp.screenContainer}>
                    <div className={stylesApp.screenHeaderContainer}>
                        <div className={stylesApp.closeContainer}>
                            <Close height={'64px'} onClick={() => {
                                document.location = '/app';
                            }} />
                        </div>
                        <ScreenTitle titles={['savior', 'Signup']} width={'95px'} />
                    </div>
                    <GroupBox width={'85%'} height={'fit-content'} title={'signup info.'}>
                        <div>
                            <img src={require('../../../assets/photos/Savior Icon.png')} width={'64px'}></img>
                            <div style={{ fontFamily: 'copperPlateGothicBoldRegular', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>SAVIOR</div>
                        </div>
                        <div className={stylesApp.separator}></div>
                        <div>
                            {/* <script src="" async></script>
                            <div id="g_id_onload"
                                style={{ border: '1px solid red' }}
                                data-client_id="103719573718-globalVars.module.scsssjl762k7a3hli94n5.apps.googleusercontent.com"
                                data-login_uri="https://sustainablife.online/authenticated"
                                data-auto_prompt="false">
                            </div>
                            <div className="g_id_signin"
                                style={{}}
                                data-type="standard"
                                data-size="large"
                                data-theme="outline"
                                data-text="sign_in_with"
                                data-shape="rectangular"
                                data-logo_alignment="left">
                            </div> */}
                            <GoogleSingin className={styles.googleButton} onClick={() => googleSignup()} />
                        </div>
                        <div className={stylesApp.separatorHalf}></div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <div className={stylesApp.listSeparatorLeft}></div>
                            <div style={{ width: 'fit-content' }}>or</div>
                            <div className={stylesApp.listSeparatorRight}></div>
                        </div>
                        <div className={stylesApp.separatorHalf}></div>
                        <FormItem title={'email'} errorMessage={invalidDate}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'241px'} height={'42px'} placeHolder={'email'} onChange={(value) => {setEmail(value)}} />
                            </div>
                        </FormItem>
                        <div className={stylesApp.separatorHalf}></div>
                        <FormItem title={'password'} errorMessage={invalidDate}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'241px'} height={'42px'} placeHolder={'password'} onChange={(value) => {setPassword(value)}} />
                            </div>
                        </FormItem>
                        <div className={stylesApp.separatorHalf}></div>
                        <FormItem title={'password retype'} errorMessage={invalidDate}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'241px'} height={'42px'} placeHolder={'password retype'} onChange={(value) => {setPasswordRetype(value)}} />
                            </div>
                        </FormItem>
                        <div className={stylesApp.separatorHalf}></div>
                        <FormItem title={'account type'} errorMessage={invalidDate}>
                            <ToggleButton switchedOnColor={lightColorMain} height={26} frameColor={darkColorMenu} buttonColor={darkColorMenu} switchedOffColor={lightColorMain} selectedTextColor={darkColorMenu}
                                leftText='business'
                                rightText='consumer'
                                displayTexts='wings'
                                onClick={(value) => {
                                    setAccountType(value ? 'consumer' : 'business');
                                }} />
                        </FormItem>
                        <div className={stylesApp.separator}></div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', position: 'absolute', bottom: '-21px', opacity: 1 }}>
                            <Button width='170px' height='42px' text='sign up' color='#002121' textColor='#FFFFFF' fontFamily='interBold' fontSize='18px' onClick={() => signup() } />
                        </div>
                    </GroupBox>
                    <div className={stylesApp.separator}></div>
                    <Link to='/appLogin' replace={true} className={stylesApp.smallLinkText}>already have account? login</Link>
                    <Link to='/resetPassword' replace={true} className={stylesApp.smallLinkText}>forgot password? reset</Link>
                    <div className={stylesApp.separator}></div>
                    <div className={stylesApp.separator}></div>
                </div>
            </Modal>
        );
};

export default AppSignup;