import React, { FunctionComponent, ReactComponentElement, useEffect, useState } from "react";
import styles from "./progress.module.scss";
import { Bell } from "../icon/icons";
import Modal from "../Modal/Modal";


interface ProgressProps {
    text?: string;
    fontSize?: string;
    opacity?: number;
}

const Progress: FunctionComponent<ProgressProps> = (props) => {
//    const [top, setTop] = useState<string>('unset');

    useEffect(() => {        
    })

    return (
        <Modal opacity={props.opacity}>
            <div className={styles.progressContainer}>
                <img src={require('../../assets/animated/progress.png')} width={'128px'}></img>
                {props.text &&
                    <div className={styles.loadingText} style={{fontSize: props.fontSize!}}>{props.text}</div>
                }
            </div>
        </Modal>
    );
}

export default Progress;