import React, { ReactElement, useContext, useEffect, useState } from 'react';

import styles from "./appContainer.module.scss";
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import Dropdown from '../../../components/DropDown/Dropdown';
import Button from '../../../components/Button/Button';
import Menu from '../menu/menu';
import nowasteLogo from "../../../assets/photos/nowasteLogo.png";
import ModalDialog from '../../../components/ModalDialog/ModalDialog';
import ReportStack from '../reportStack/reportStack';
import Appointment from '../appointment/appointment';
import { getCategories } from '../../../restAPI/category';
import { GlobalContext } from '../../../handlers/GlobalContext';
import NowasteMap from '../nowasteMap/nowasteMap';
import Busy from '../../../components/loading/Busy';
import Landing from '../landing/landing';
import PickupProgram from '../collecting/pickupProgram/PickupProgram';
import Progress from '../../../components/progress/Progress';
import { Settings } from '../../../assets/statics/Settings';
import GlobalWrapper from '../../../handlers/GlobalWrapper';
import Modal from '../../../components/Modal/Modal';
import { PopupsContext } from '../../../handlers/PopupsContext';
import PopupsWrapper from '../../../handlers/PopupsWrapper';


const AppContainer = () => {

    const { updateCategory, user, notifications } = useContext(GlobalContext);
    const { showAlertPopup, showModal, showBusy, hideBusy } = useContext(PopupsContext);

    const [reportStackVisible, setReportStackVisible] = useState<boolean>(false);
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [showAppointment, setShowAppointment] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [showLanding, setShowLanding] = useState<boolean>(false);
    const [notifCount, setNotifCount] = useState<number>(0);
    const [categories, setCategories] = useState<[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>('');

    const render = (status: Status): ReactElement => {
        switch (status) {
            case Status.LOADING:
                return (<Busy message='' type='busy'/>);
            case Status.FAILURE:
                return (<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', zIndex: '100', backgroundColor: 'red' }}>
                    Error LOADING Google Maps!
                </div>);
            case Status.SUCCESS:
                return <NowasteMap center={{ lat: 41.047729660923224, lng: 28.989871783438957 }} zoom={12} />;
        }
    };

    const hideModalnContent = () => {
        setShowMenu(false);
    }

    // useEffect(() => {
    //     (async () => {
    //     })()
    // }, [])

    useEffect(() => {

            if (notifications !== undefined) {
                console.log('### notif stat: ' + notifications!);
                const pickups = JSON.parse(notifications).pickups;
                if (pickups !== undefined)
                    setNotifCount(pickups.active);
            }
        
        if (sessionStorage.getItem('landed') === null) {
            setShowLanding(true);
        }
        (async () => {
            showBusy('busy', 'loading cats');
            try {                
                    const categoryList = await getCategories();
                    if (categoryList !== undefined) {
                        setCategories(JSON.parse(JSON.stringify(JSON.parse(categoryList).result.sort((a: any, b: any) => { return (a.displayable > b.displayable ? 1 : -1) })).replaceAll('pkCategory', 'value').replaceAll('displayable', 'label')));
                    }
                
            } catch (error) {
                showAlertPopup('server connection lost ...' + error, 'error', 5000);
            }
            hideBusy();
        })()
    }, [notifications])

    const modalOK = (value: boolean): boolean => {
        console.log('OK clicked' + value);
        return true;
    }

    const modalCancel = () => {
        console.log('CANKEL clicked');
    }

    return (
        <div style={{ position: 'relative', zIndex: '0' }}>
            {reportStackVisible &&
            <ReportStack onClose={() => { setReportStackVisible(false) }} />
        }
            {showLanding &&
                <Landing onClose={() => setShowLanding(false)} />
            }
            {showMessage &&
                <ModalDialog title='alert.' body='In case of cancelling your program, you will lose 5 points!' okLabel='proceed' onCancel={() => { setShowMessage(false) }} />
            }
            {showMenu &&
                <Menu onClose={() => { setShowMenu(false); }} />
            }
            {showAppointment &&
                <Appointment onClose={() => { setShowAppointment(false) }} onOK={() => { setShowAppointment(false) }}></Appointment>
            }
            <div>
                <div className={styles.headerPanel}>
                    <div className={styles.headerInnerPanel}>
                        <div className={styles.text} onClick={() => { setShowMessage(true) }}>nowaste</div>
                        <img src={nowasteLogo} className={styles.logo} alt='nowaste Logo' onClick={() => {
                            setShowMenu(true);
                        }} />
                    </div>
                </div>
                <div className={styles.notificationsRow} onClick={() => {
                    document.location = '/pickupProgram';
                }}>
                    <div className={styles.counter}>{notifCount > 0 ? notifCount : ''}</div>
                </div>
            </div>
            <div className={styles.footerPanel}>
                <div className={styles.toolsRow}>
                    <Dropdown width='70%' height='42px' items={categories} hasShadow={true} listStyle={'top'} placeHolder='category'
                        onChange={(item) => {
                            setSelectedCategory(item.value);
                            updateCategory(item.value);
                        }}></Dropdown>
                    <div className={styles.splitter}></div>                    
                    <Button width='42px' height='42px' image={require('../../../assets/icons/stack.png')} imageWidth='32px' imageHeight='32px' hasShadow={true}
                        onClick={async () => {
                            if (user)
                                //showAlertPopup('more accurate you are, more TRECOIN you get!', 'info', 3000);
                                setReportStackVisible(true);
                                //document.location = '/reportStack';
                            else
                                showAlertPopup('please LOGIN to report your stacks!', 'error', 5000);
                        }}></Button>                        
                </div>
            </div>
            <Wrapper apiKey={"AIzaSyDHy-raustGotVuofGKl9VuTtkmo7ZUuXE"} render={render} />
        </div>
    );
};

export default AppContainer;