import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./dropdown.module.scss";
import { ReactComponent as DropDownButton } from "./assets/icon/dropDownButton.svg";
import Modal from "../Modal/Modal";
import Picker from "react-mobile-picker";

interface DropdownProps {
    fontFamily?: string;
    fontSize?: string;
    placeHolder?: string;
    textColor?: string;
    color?: string;
    borderColor?: string;
    disabledColor?: string;
    disabled?: boolean;
    hasShadow?: boolean;
    width?: string;
    height?: string;
    items?: { [item: string]: any }[];
    listStyle?: 'top' | 'bottom' | 'centerScreen';
    onClick?: (value: any) => void;
    onChange?: (value: any) => void;
}

const Dropdown: FunctionComponent<DropdownProps> = (props) => {

    const [showList, setShowList] = useState(false);
    const [listItems, setListItems] = useState<{ [item: string]: any }[]>([]);
    const [selectedValue, setSelectedValue] = useState<string>('');
    const [listTop, setListTop] = useState<string>('0px');
    const [selectedItem, setSelectedItem] = useState({
        value: '',
        label: ''
    });

    const itemSelected = (selectedItem: any) => {
        setSelectedValue(selectedItem.label);
        setShowList(false);
        if (props.onChange)
            props.onChange(selectedItem);
    }

    useEffect((() => {
        setListItems(props.items!);
        switch (props.listStyle!) {
            case 'top':
                setListTop('-150px');
                break;
            case 'bottom':
                setListTop(`${props.height}`);
                break;
        }
    }), [props.items]);

    return (
        <div id='divContent' className={styles.dropDownContainer}
            style={{
                position: props.listStyle !== 'centerScreen' ? 'relative' : 'unset',
                boxShadow: props.hasShadow ? '#00000088 1px 2px 2px' : '',
                fontFamily: props.fontFamily!, fontSize: props.fontSize!,
                width: props.width!,
                height: props.height!,
                backgroundColor: !props.disabled ? props.color! : props.disabledColor!,
                borderColor: !props.disabled ? props.borderColor! : props.disabledColor!,
            }}
            onClick={(e) => {
                if (!props.disabled && props.onClick)
                    props.onClick(e);
            }}>
            <input autoComplete="one-time-code"
                onFocus={(e) => {
                    e.target.select();

                    setShowList(true);
                }}
                onClick={() => { setShowList(true) }}
                onChange={(e) => {
                    setSelectedValue(e.target.value);
                    if (e.target.value !== '')
                        setListItems(listItems.filter(findingItem => findingItem.label.toLowerCase().includes(e.target.value.toLowerCase())));
                    else
                        setListItems(props.items ?? [{ label: '', value: '' }]);
                }}
                style={{
                    padding: '0px 10px',
                    width: '100%', height: '100%',
                    backgroundColor: "transparent", borderColor: "transparent",
                    fontFamily: props.fontFamily! ?? 'inherit',
                    fontSize: props.fontSize! ?? 'inherit',
                    color: props.color!
                }}
                placeholder={props.placeHolder!}
                value={selectedValue!}
            />
            <div className={styles.buttonContainer}>
                <DropDownButton className={styles.button} onClick={() => setShowList(!showList)} />
            </div>
            {showList &&
                <div className={styles.listContainer}
                    style={{
                        top: listTop,
                        position: props.listStyle !== 'centerScreen' ? 'absolute' : 'fixed',
                        width: props.listStyle !== 'centerScreen' ? '250px' : '100vw',
                        height: props.listStyle !== 'centerScreen' ? '150px' : '100vh',
                        borderWidth: props.listStyle !== 'centerScreen' ? '1px' : '0px',
                        borderRadius: props.listStyle !== 'centerScreen' ? '10px' : '0px',
                    }}>
                    <div className={styles.list}
                        style={{
                            alignItems: props.listStyle !== 'centerScreen' ? 'flex-start' : 'center',
                            justifyContent: props.listStyle !== 'centerScreen' ? 'flex-start' : 'center',
                            height: props.listStyle !== 'centerScreen' ? '150px' : '100vh',
                        }}
                    //onBlur={() => setShowList(false)}
                    >
                        {
                            props.listStyle === 'centerScreen' &&
                            <Picker value={selectedItem} onChange={setSelectedItem} wheelMode='natural'>
                                <Picker.Column name={''}>{
                                    listItems.map((item: any) => (
                                        <Picker.Item key={item} value={item.value} onClick={() => { itemSelected(item) }}>
                                            <div>{item.label}</div>
                                        </Picker.Item>
                                    ))}
                                </Picker.Column>
                            </Picker>
                        }

                        {props.listStyle !== 'centerScreen' &&
                            listItems.map((item) => {
                                return (
                                    <div className={styles.items} onClick={() => itemSelected(item)} title={item.label} key={item.value}>{item.label}</div>
                                )
                            })
                        }
                    </div>
                </div>



                // <div className={styles.listContainer} 
                //     style={{
                //         top: listTop,
                //         position: props.listStyle !== 'centerScreen' ? 'absolute' : 'fixed',
                //         width: props.listStyle !== 'centerScreen' ? '250px' : '100vw',
                //         height: props.listStyle !== 'centerScreen' ? '150px' : '100vh',
                //         borderWidth: props.listStyle !== 'centerScreen' ? '1px' : '0px',
                //         borderRadius: props.listStyle !== 'centerScreen' ? '10px' : '0px',
                //         }}>
                //     {props.listStyle === 'centerScreen' && <div className={styles.listFaderTop}></div>}
                //     <div className={styles.list}
                //         style={{
                //             //position: props.listStyle !== 'centerScreen' ? 'absolute' : 'unset',
                //         }}
                //         onBlur={() => setShowList(false)}>
                //         {                           
                //             listItems.map((item) => {
                //                 return (
                //                     <div className={styles.items} onClick={() => itemSelected(item)} title={item.label} key={item.value}>{item.label}</div>
                //                 )
                //             })
                //         }
                //     </div>
                //     {props.listStyle === 'centerScreen' && <div className={styles.listFaderBottom}></div>}
                // </div>
            }
        </div>
    );
}

export default Dropdown;