import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./reportStack.module.scss";
import stylesApp from "../app.module.scss";
import Modal from '../../../components/Modal/Modal';
import { Close } from '../../../components/icon/icons';
import ScreenTitle from '../../../components/inappComponents/ScreenTitles/ScreenTitle';
import GroupBox from '../../../components/GroupBox/GroupBox';
import Dropdown from '../../../components/DropDown/Dropdown';
import FormItem from '../../../components/FormItem/FormItem';
import Textbox from '../../../components/Textbox/Textbox';
import Button from '../../../components/Button/Button';
import { getCategories } from '../../../restAPI/category';
import { DateCalendar, LocalizationProvider, TimeClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'react-image-picker-editor/dist/index.css'
import Progress from '../../../components/progress/Progress';
import LargeImage from '../../../components/LargeImage/LargeImage';
import { removeTempStackPhotos, recordStackPhoto, removeTempStackPhoto, recordStack } from '../../../restAPI/stack';
import { GlobalContext } from '../../../handlers/GlobalContext';
import { userContact } from '../../../restAPI/contact';
import { PopupsContext } from '../../../handlers/PopupsContext';
import PopupsWrapper from '../../../handlers/PopupsWrapper';

interface ReportStackProps {

    onClose?: () => void;
}

const ReportStack: FunctionComponent<ReportStackProps> = (props) => {

    const { updateCategory, user, notifications } = useContext(GlobalContext);
    const { showAlertPopup, showInfoPopup, showModal, showBusy, hideBusy } = useContext(PopupsContext);

    const initialRef: any = null;
    const stackFilePicker = useRef(initialRef);

    const [categories, setCategories] = useState<any>('');
    const [selectedCategory, setSelectedCategory] = useState<any>('');    
    const [volume, setVolume] = useState<any>('');
    const dateOptions: any = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    const today = new Intl.DateTimeFormat(undefined, dateOptions).format(new Date());
    const [loading, setLoading] = useState<boolean>(false);
    const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
    const [showTimePicker, setShowTimePicker] = useState<boolean>(false);
    const [redeemDate, setRedeemDate] = useState<string>('');
    const [redeemTime, setRedeemTime] = useState<string>('12:00');
    const [showLargeImage, setShowLargeImage] = useState<boolean>(false);
    const [largeImage, setLargeImage] = useState<string>('');
    const [photos, setPhotos] = useState<string[]>([]);
    const [uploading, setUploading] = useState<boolean>(false);
    const [invalidDate, setInvalidDate] = useState<string>('');
    
    let time = 0;

    useEffect(() => {
        (async () => {
            showBusy('loading', '');
            setLoading(true);

            try{
            const res = await removeTempStackPhotos(user!.pkUser);
            }catch(error){
                console.log('---{ remove temp stack photos: ' + error);
            }
            
            try {
                const categoryList = await getCategories();
                if (categoryList !== undefined) {
                    setCategories(JSON.parse(JSON.stringify(JSON.parse(categoryList).result!.sort((a: any, b: any) => { return (a.displayable > b.displayable ? 1 : -1) })).replaceAll('pkCategory', 'value').replaceAll('displayable', 'label')));
                }
            } catch (error) {
                showAlertPopup('server connection lost ...', 'error', 5000);
            }
            setLoading(false);
            hideBusy();
        })()
    }, [])

    useEffect(() => {
    }, [uploading])


    const addStackPhoto = (dataUri: any) => {
        if (dataUri !== null) {
            setUploading(true);
            let photoJSON = null;
            let activeIndex = photos.length;
            const photoURL = URL.createObjectURL(dataUri);
            photoJSON = JSON.stringify({ 'id': null, 'photo': photoURL, 'index': activeIndex });
            const photosTemp = [...photos];//spread operator
            photosTemp.push(photoJSON);
            setPhotos(photosTemp);

            const reader = new FileReader();
            reader.onloadend = async () => {
                const base64Image = reader.result!.toString().split(',')[1];
                const result = await recordStackPhoto(undefined, undefined, user!.pkUser, base64Image, dataUri, activeIndex);

                const resultJSON = JSON.parse(result);
                photosTemp[resultJSON.index] = JSON.stringify({ 'id': resultJSON.result === undefined ? 'undefined' : resultJSON.result, 'photo': JSON.parse(photosTemp[resultJSON.index]).photo, 'index': resultJSON.index });
                setPhotos(photosTemp);
                setUploading(false);
            }
            reader.readAsDataURL(dataUri);
        }
    }

    const removeStackPhoto = (index: number) => {
        const photosTemp = [...photos];
        photosTemp.splice(index, 1);
        const photoID = JSON.parse(photos[index]).id;        
        if (photoID !== 'undefined')            
            removeTempStackPhoto(photoID, user!.pkUser);
        setPhotos(photosTemp);
    }

    const addStack = async () => {
        showBusy('saving', 'Saving ...');
        try {
            if (user === undefined) {
                showAlertPopup('please first LOGIN to your account!', 'error', 3000);
                return;
            }
            const contact = await userContact(user.pkUser, 'ADDRESS');
            const redeemsAt = new Date(Number(redeemDate.split('/')[0]), Number(redeemDate.split('/')[1]), Number(redeemDate.split('/')[2]), Number(redeemTime.split(':')[0]), Number(redeemTime.split(':')[1])).toISOString();
            const result = await recordStack(contact[0].pkContact, selectedCategory, volume, redeemsAt);
            console.log('-----==={ addStack res: ' + result.stackId + ' by USER: ' + user.pkUser);
            if (result) {
                if (result.linkedPhotos === photos.length) {
                    console.log('wrote: ' + result.linkedPhotos + ' from ' + photos.length);
                }
                showAlertPopup('stack report done!', 'message', 3000);
                props.onClose!();
            }
            else if (result === undefined)
                showAlertPopup('something went wrong!', 'error', 3000);
        }
        catch (error) {
            console.log(error);
            showAlertPopup('something went wrong!', 'error', 3000);
        }
        hideBusy();
    }

    if (showLargeImage)
        return (<LargeImage image={largeImage} onClose={() => { setShowLargeImage(false) }} />);
    else
        return (
            <>
            <Modal color='#D9D9D9'>
                <div className={stylesApp.screenContainer}>
                    {/* {showAlertDialog &&
                        <ModalDialog onCancel={() => setShowAlertDialog(false)} title={alertDialogTitle} body={alertDialogBody} />
                        } */}
                    <div className={stylesApp.screenHeaderContainer}>
                        <div className={stylesApp.closeContainer}>
                            <Close height={'64px'} onClick={() => {
                                props.onClose!();
                            }} />
                        </div>
                        <ScreenTitle titles={['report', 'Stack']} width={'95px'} />
                    </div>
                    <GroupBox width={'85%'} height={'fit-content'} title={'Details'}>
                        <FormItem title={'stack type'} titleDescription={'(separation)'} onDescriptionClick={() => {                                
                                showInfoPopup('separation gains', 'type mixed recyclable stacks and incorrect stacks will not help you to get more TRECOIN, if 10 collectors report your stacks inaccurate or you select mostly mix types, your rating will go lower! try even separating more, like splitting wires\' cores of their plastics to report in separate stacks!');
                            }}>
                            <Dropdown width={'240px'} height={'42px'} items={categories} disabled={loading} listStyle={'centerScreen'} 
                                onChange={(item) => { 
                                    console.log('cat ' + item.value); 
                                    setSelectedCategory(item.value); 
                                }} />
                        </FormItem>
                        <div className={stylesApp.separator}></div>
                        <FormItem title={'amount'} titleDescription={'(estimated)'} onDescriptionClick={() => {                                
                                showInfoPopup('accuracy gains', 'more accurate you are, more TRECOIN you get! you may rather use a Scale of any type to report your stack weight accurately, as it leads to get more TRECOINs! (inaccurate report lowers your rating in Gaming Platform!)');
                            }}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <Textbox width={'133px'} height={'42px'} placeHolder='0' 
                                    onChange={(value) => {
                                        console.log('vol ' + value);     
                                        setVolume(value);
                                    }} />
                                <div className={stylesApp.splitter}></div>
                                Kg
                                {/* <Dropdown width={'97px'} height={'42px'} items={units} disabled={loading} listStyle={'centerScreen'} 
                                onChange={(item) => { 
                                    console.log('unit ' + item.value); 
                                    setSelectedUnit(item.value); 
                                    }}/> */}
                            </div>
                        </FormItem>
                        <div className={stylesApp.separator}></div>
                        <FormItem title={'valid to'} errorMessage={invalidDate}  titleDescription={'(longer validity)'} onDescriptionClick={() => {
                                showInfoPopup('longer worths', 'the longer you can keep your stack, more chance to help collectors find you, it also oncreases your district rating which will lead to city hall advantages for the area you live!');
                            }}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'140px'} height={'42px'} placeHolder={today} text={redeemDate} onClick={() => { setShowDatePicker(true) }} />
                                <div className={stylesApp.splitter}></div>
                                <Textbox width={'90px'} height={'42px'} placeHolder={'12:00'} text={redeemTime} onClick={() => { setShowTimePicker(true) }} />
                            </div>
                        </FormItem>
                        <div className={stylesApp.separator}></div>

                        <div className={styles.images}>
                            <Button width={'64px'} height={'64px'} border={'1px dotted #0A4000'}
                                margin={'10px 5px'}
                                image={require('../../../assets/icons/gallery.png')}
                                color={'#D9D9D9'} imageWidth={'52px'} imageHeight={'33px'}
                                disabled={uploading}
                                onClick={() => { stackFilePicker.current!.click() }}
                                />
                            <input
                                type="file"
                                id='stackFilePickerId'
                                name='stackFilePickerName'
                                accept="image/jpg, image/png, image/bmp, image/jpeg, image/webp"
                                ref={stackFilePicker}
                                style={{ display: 'none' }}
                                onChange={(event) => {
                                    if (event.target.files !== null) {
                                        console.log(event.target.files[0]);
                                        addStackPhoto(event.target.files[0]);
                                    }
                                }}
                                />
                            {

                                photos.map((imageData: string, index: number) => {
                                    const photoJSON = JSON.parse(imageData);
                                    console.log(photos);
                                    console.log('MAPPING photo no.(' + photoJSON.index + ',' + index + ')>>>>>>> ' + photoJSON.id);
                                    return (
                                        <div className={styles.imagePlaceholder}
                                        onClick={() => {
                                            if (photoJSON.id !== 'undefined') {
                                                setLargeImage(photoJSON.photo);
                                                    setShowLargeImage(true);
                                                }
                                            }}>
                                            <img src={photoJSON.photo} key={photoJSON.id} />
                                            <Button overlaySpec={photoJSON.id === 'undefined' ? 'center-center' : 'top-right'} color='transparent'
                                                border='0px solid transparent'
                                                width={photoJSON.id === 'undefined' ? '100%' : '24px'}
                                                height={photoJSON.id === 'undefined' ? '100%' : '24px'}
                                                imageWidth={photoJSON.id === 'undefined' ? '80%' : '24px'}
                                                imageHeight={photoJSON.id === 'undefined' ? '80%' : '24px'}
                                                image={photoJSON.id === 'undefined' ? require('../../../assets/icons/alertedRemove.png') : require('../../../assets/icons/closeRed.png')}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    removeStackPhoto(index);
                                                }} key={'btn' + photoJSON.id} />
                                            {(photoJSON.id === null) &&
                                                <Progress opacity={0.9} />
                                            }
                                        </div>
                                    )
                                })
                            }

                        </div>
                        <div className={stylesApp.separator}></div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', position: 'absolute', bottom: '-21px', opacity: 1 }}>
                            <Button width='170px' height='42px' text='submit' color='#002121' textColor='#FFFFFF' fontFamily='interBold' fontSize='18px' onClick={() => { addStack() }} />
                        </div>
                    </GroupBox>
                    <div className={stylesApp.separator}></div>
                    <div className={stylesApp.separator}></div>
                </div>

                {showDatePicker &&
                    <Modal>
                        <div className={stylesApp.timePicker}>
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateCalendar onChange={(value) => {
                                        setRedeemDate(value.$y + '/' + value.$M + '/' + value.$D);
                                    }}></DateCalendar>
                                </LocalizationProvider>
                            </div>
                            <div className={stylesApp.mixedDateControls}>
                                <div className={styles.splitter}></div>
                                <Button width='130px' height='42px' text='cancel' borderColor='#002121' textColor='#002121' fontFamily='interBold' fontSize='18px' onClick={() => setShowDatePicker(false)} />
                                <div className={styles.splitter}></div>
                                <Button width='130px' height='42px' text='set' borderColor='#002121' textColor='#002121' fontFamily='interBold' fontSize='18px' onClick={() => setShowDatePicker(false)} />
                                <div className={styles.splitter}></div>
                            </div>
                        </div>
                    </Modal>
                }

                {showTimePicker &&
                    <Modal>
                        <div className={stylesApp.timePicker}>
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimeClock ampm={false} onChange={(value) => { setRedeemTime(value.$H + ':' + value.$m) }}></TimeClock>
                                </LocalizationProvider>
                            </div>
                            <div className={styles.displayingClock} >
                                <div onClick={() => {
                                }}>
                                    {redeemTime}
                                </div>
                            </div>
                            <div className={stylesApp.mixedDateControls}>
                                <div className={styles.splitter}></div>
                                <Button width='130px' height='42px' text='cancel' borderColor='#002121' textColor='#002121' fontFamily='interBold' fontSize='18px' onClick={() => setShowTimePicker(false)} />
                                <div className={styles.splitter}></div>
                                <Button width='130px' height='42px' text='set' borderColor='#002121' textColor='#002121' fontFamily='interBold' fontSize='18px' onClick={() => setShowTimePicker(false)} />
                                <div className={styles.splitter}></div>
                            </div>
                        </div>
                    </Modal>
                }
            </Modal>
</>
        );
};

export default ReportStack;