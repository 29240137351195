import React, { useEffect } from "react";
import { Routes, Route, useLocation, redirectDocument } from "react-router-dom";

import AppContainer from "../pages/trashkingApp/appContainer/appContainer";
import AppSignup from "../pages/trashkingApp/appSignup/appSignup";
import PickupProgram from "../pages/trashkingApp/collecting/pickupProgram/PickupProgram";
import ProgramDetails from "../pages/trashkingApp/collecting/programDetails/programDetails";
import Appointment from "../pages/trashkingApp/appointment/appointment";
import AppLogin from "../pages/trashkingApp/appLogin/appLogin";
import Profile from "../pages/trashkingApp/profile/profile";
import NeighborhoodExtractor from "../pages/trashkingApp/neighborhoodExtractor/neighborhoodExtractor";
import ReportStack from "../pages/trashkingApp/reportStack/reportStack";

const AppRoutes = () => {

    const location = useLocation();
    const authenticableComponents = [
        '/stackList', 
        '/appointment', 
        '/stackListDetails', 
        '/profile',
        '/pickupProgram',
        '/reportStack'
    ];

    const requiresAuthentication = (path: string) => {
        const result = authenticableComponents.some((component) => {
            return path.startsWith(component);
        });
        return result;
    }


    useEffect(() => {

        if (requiresAuthentication(location.pathname) && localStorage.getItem("activeAccount") === null) {
            document.location = '/appLogin';
        }
    })

    return (
        <Routes>
            <Route path={"/"} element={<AppContainer />} />
            <Route path={"/app"} element={<AppContainer />} />
            <Route path={"/trashkingApp"} element={<AppContainer />} />
            <Route path={"/appLogin"} element={<AppLogin />} />
            <Route path={"/appSignup"} element={<AppSignup />} />
            <Route path={"/neighborhoodExtractor"} element={<NeighborhoodExtractor />} />


            <Route path={"/pickupProgram"} element={<PickupProgram />} />
            <Route path={"/reportStack"} element={<ReportStack />} />
            <Route path={"/profile"} element={<Profile />} />
            <Route path={"/appointment"} element={<Appointment onClose={() => { }} onOK={() => { }} />} />
            <Route path={"/programDetails"} element={<ProgramDetails pickupProgram="" />} />
            <Route path={"/programDetails/:category/:neighbourhood/:date"} element={<ProgramDetails pickupProgram="" />} />
        </Routes>
    );
};

export default AppRoutes;
