import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./appLogin.module.scss";
import stylesApp from "../app.module.scss";
import Modal from '../../../components/Modal/Modal';
import { Close } from '../../../components/icon/icons';
import ScreenTitle from '../../../components/inappComponents/ScreenTitles/ScreenTitle';
import GroupBox from '../../../components/GroupBox/GroupBox';
import FormItem from '../../../components/FormItem/FormItem';
import Textbox from '../../../components/Textbox/Textbox';
import Button from '../../../components/Button/Button';
import { GoogleSingin } from '../../../components/icon/icons';
import { oauthSignIn, requestInfo, storeUser } from '../../../handlers/googleSignIn';

import { authenticateUser } from '../../../restAPI/user';
import { Link, Navigate } from 'react-router-dom';
import { GlobalContext } from '../../../handlers/GlobalContext';
import zIndex from '@mui/material/styles/zIndex';
import { PopupsContext } from '../../../handlers/PopupsContext';

interface AppLoginProps {
    onClose?: () => void;
}

const AppLogin: FunctionComponent<AppLoginProps> = (props) => {

    const textbox = useRef();
    const { updateCategory, user, notifications } = useContext(GlobalContext);
    const { showAlertPopup, showModal, showBusy, hideBusy } = useContext(PopupsContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [invalidDate, setInvalidDate] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const dateOptions: any = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    const today = new Intl.DateTimeFormat(undefined, dateOptions).format(new Date());

    useEffect(() => {
        (async () => {
            const script = document.createElement("script");
            script.src = "https://accounts.google.com/gsi/client";
            script.async = true;
            document.body.appendChild(script);
        })()
    }, [])

    const googleSignup = () => {
        oauthSignIn();
    }

    const authenticate = async () => {
        try {
            setLoading(true);
            const loginResult = await authenticateUser(email, password);            
            
            localStorage.setItem('activeAccount', JSON.stringify(loginResult));
            setLoading(false);
            document.location = '/app';
            
            setLoading(false);
        }
        catch (error) {
            setLoading(false);
            console.log(error);
            showAlertPopup('invalid username/password!', 'error', 5000);
        }
    }

    if (loading)
        return (<Modal zIndex='1000'>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <img src={require('../../../assets/animated/progress.png')} width={'128px'}></img>
                <div style={{ color: '#7D9B78', padding: '15px', fontFamily: 'interExtraBold', fontSize: '28px', letterSpacing: '5px' }}>Loading...</div>
            </div>
        </Modal>);
    else
        return (
            <Modal color='#D9D9D9' zIndex='1000'>
                <div className={stylesApp.screenContainer}>
                    <div className={stylesApp.screenHeaderContainer}>
                        <div className={stylesApp.closeContainer}>
                            <Close height={'64px'} onClick={() => {
                                document.location = '/app';
                            }} />
                        </div>
                        <ScreenTitle titles={['savior', 'Login']} width={'95px'} />
                    </div>
                    <GroupBox width={'85%'} height={'fit-content'} title={'login info'}>
                        <div>
                            <img src={require('../../../assets/photos/Savior Icon.png')} width={'128px'}></img>
                            <div style={{ fontFamily: 'copperPlateGothicBoldRegular', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>SAVIOR</div>
                        </div>
                        <div className={stylesApp.separator}></div>
                        <div>
                            <script src="" async></script>
                            {/* <div id="g_id_onload"
                                style={{ border: '1px solid red' }}
                                data-client_id="103719573718-s86m327ga2pcngtsjl762k7a3hli94n5.apps.googleusercontent.com"
                                data-login_uri="https://sustainablife.online/authenticated"
                                data-auto_prompt="false">
                            </div>
                            <div className="g_id_signin"
                                style={{}}
                                data-type="standard"
                                data-size="large"
                                data-theme="outline"
                                data-text="sign_in_with"
                                data-shape="rectangular"
                                data-logo_alignment="left">
                            </div> */}
                            <GoogleSingin className={styles.googleButton} onClick={() => googleSignup()} />
                        </div>
                        <div className={stylesApp.separator}></div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <div className={stylesApp.listSeparatorLeft}></div>
                            <div style={{ width: 'fit-content' }}>or</div>
                            <div className={stylesApp.listSeparatorRight}></div>
                        </div>
                        <div className={stylesApp.separator}></div>
                        <FormItem title={'email'} errorMessage={invalidDate}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'241px'} height={'42px'} placeHolder={'email'} onChange={(value) => { setEmail(value) }} />
                            </div>
                        </FormItem>
                        <FormItem title={'password'} errorMessage={invalidDate}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'241px'} height={'42px'} placeHolder={'password'} type={'password'} onChange={(value) => { setPassword(value) }} />
                            </div>
                        </FormItem>
                        <div className={stylesApp.separator}></div>
                        <div className={stylesApp.separator}></div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', position: 'absolute', bottom: '-21px', opacity: 1 }}>
                            <Button width='170px' height='42px' text='log in' color='#002121' textColor='#FFFFFF' fontFamily='interBold' fontSize='18px' onClick={() => { authenticate() }} />
                        </div>
                    </GroupBox>
                    <div className={stylesApp.separator}></div>
                    <Link to='/appSignup' replace={true} className={stylesApp.smallLinkText}>do not have account? signup</Link>
                    <div className={stylesApp.separator}></div>
                    <div className={stylesApp.separator}></div>
                </div>
            </Modal>
        );
};

export default AppLogin;