import { FunctionComponent } from "react";
import { Settings } from "../assets/statics/Settings";

export interface user {
    uId: string|null;
    username: string;
    password: string;
    name: string|null;
    surname: string|null;
}

export const signupUser = async (email:string, password:string): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/user/emailAccount", {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                email,
                password
            })
        }).then((response) => {
            response.json().then((result) => {
                resolve(result);
            }).catch((error) => {reject(undefined)});            
        }).catch((error) => {
            reject(undefined);
        });
    });
}

export const updateProfile = async (
    pkUser: string,
    name: string | undefined,
    surname: string | undefined,
    phone: string | undefined,
    address: string | undefined,
    location: string | undefined): Promise<any | undefined> => {

    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/user/profile", {
            method: 'PUT',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React'
            }),
            body: JSON.stringify({
                pkUser,
                name,
                surname,
                phone,
                address, 
                location
            })
        }).then((response) => {
            response.json().then((result) => {
                resolve(result);
            }).catch((error) => { reject(undefined) });
        }).catch((error) => {
            reject(undefined);
        });
    });
}

export const updateProfilePhoto = async (
    pkUser: string,
    photo: string, 
    fileObject: any): Promise<any | undefined> => {

    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/user/profilePhoto", {
            method: 'PUT',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React'                
            }),            
            body: JSON.stringify({
                pkUser,
                filename: fileObject.name,
                MIMEType: fileObject.type,
                size: fileObject.size,
                base64: photo
            })
        }).then((response) => {
            console.log('propho: ' + response);
            console.log('prophostat: ' + response.status);
            if(response.status === 200)
                resolve(true);
            else
                reject(undefined);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const getProfilePhoto = async (
    pkUser: string): Promise<any | undefined> => {
        console.log(`que l'heure ici`);
        
        return new Promise((resolve, reject) => {        
        console.log(`que l'heure la-bas`);
        fetch(Settings.api.path + "/user/profilePhoto", {
            method: 'GET',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
                'pkUser': pkUser
            })
        }).then((response) => {
            console.log(`que l'hour` + response);
            response.json().then((result) => {                                
                resolve(result.result);
            }).catch((error) => {reject(undefined)});
        }).catch((error) => {
            reject(undefined);
        });
    });
}

export const getProfile = async (
    pkUser: string): Promise<any | undefined> => {

    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/user/profile", {
            method: 'GET',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
                'pkUser': pkUser
            })
        }).then((response) => {
            response.json().then((result) => {                
                resolve(result);
            }).catch((error) => {reject(undefined)});
        }).catch((error) => {
            reject(undefined);
        });
    });
}

export const authenticateUser = async (email:string, password:string): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/user/authentication", {
            method: 'GET',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
                'Authentication': JSON.stringify({email, password})
            })
        }).then((response) => {
            response.json().then((result) => {
                    if (response.status === 200)
                        resolve(result);
                    if (response.status === 404)
                        reject(result.result);
                }).catch((error) => { reject(undefined) });
        }).catch((error) => {
            reject(undefined);
        });
    });
}