import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { Back, Close } from '../../../../components/icon/icons';
import ScreenTitle from '../../../../components/inappComponents/ScreenTitles/ScreenTitle';
import Button from '../../../../components/Button/Button';
import styles from "./pickupProgram.module.scss";
import stylesApp from "../../app.module.scss";
import { Link, Navigate, redirect } from 'react-router-dom';
import ToggleButton from '../../../../components/ToggleButton/ToggleButton';
import List from '../../../../components/List/List';
import ModalDialog from '../../../../components/ModalDialog/ModalDialog';
import vars from "../../../../globalVars.module.scss";
import { GlobalContext } from '../../../../handlers/GlobalContext';
import { getPickupPrograms } from '../../../../restAPI/pickup';
import Appointment from '../../appointment/appointment';
import Modal from '../../../../components/Modal/Modal';
import ProgramDetails from '../programDetails/programDetails';
import StackDetails from '../stackDetails/stackDetails';
import { consumers } from 'stream';
import { PopupsContext } from '../../../../handlers/PopupsContext';
const { lightColorMain } = vars;

interface PickupProgramProps {

    onClose?: () => void;
}

const PickupProgram: FunctionComponent<PickupProgramProps> = (props) => {

    const { updateCategory, user, notifications } = useContext(GlobalContext);
    const { showAlertPopup, showModal, showBusy, hideBusy } = useContext(PopupsContext);

    const [showCancelAlert, setShowCancelAlert] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [clickedHeader, setClickedHeader] = useState<string>('Pickup');
    const [listType, setListType] = useState<'all' | 'active'>('active');
    const [listPrograms, setListPrograms] = useState<any[]>([]);
    const dateOptions: any = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    const today = new Intl.DateTimeFormat(undefined, dateOptions).format(new Date());

    useEffect(() => {
        (async () => {
            showBusy('loading', '');
            let appointmentList;
            if (user!) {
                console.log('User: '+ user);
                appointmentList = await getPickupPrograms(user.pkUser, user.accountType, 'all', 0, 0);
            }

            if (appointmentList !== undefined)
                setListPrograms(await JSON.parse(appointmentList).result);
            hideBusy();
        })()
    }, [user, listType])

    useEffect(() => {

    }, [clickedHeader])

    const refreshHeaderClasses = (e: any) => {
        setClickedHeader(e.target.id);
    }

    if (showDetails && user.accountType === 'consumer')
            return (<Modal opacity={1}>
                <StackDetails stackId={selectedItem.fkStack} onClose={() => setShowDetails(false)} />
            </Modal>);
    if (showDetails && user.accountType === 'collector')
            return (<Modal opacity={1}>
                <ProgramDetails pickupProgram={selectedItem} onClose={() => setShowDetails(false)} />
            </Modal>);
    else
        return (

            <div className={stylesApp.pageContainer}>
                {showCancelAlert &&
                    <ModalDialog title='alert.' body='In case you cancel your whole pickup program, you will lose 15 points! (accet: ok , reject: close)' okLabel='ok' onCancel={() => setShowCancelAlert(false)} />
                }
                <div className={stylesApp.pageHeaderContainer}>
                    <div className={stylesApp.backContainer}>
                        <Back height={'64px'} onClick={() => { document.location = '/app' }} />
                    </div>
                    <ScreenTitle titles={['pickup', 'Program']} width={'115px'} />
                    <div className={stylesApp.emptyCell}></div>
                </div>
                <div className={styles.toggleBox}>
                    <ToggleButton switchedOnColor={lightColorMain} height={42}
                        leftText='active' rightText='all' displayTexts='right'
                        selectedTextColor=''
                        image={require('../../../../assets/icons/bell.png')} imageHeight='32px'
                        onClick={(e) => e ? setListType('active') : setListType('all')} />
                    <div className={styles.listingType}>{'programs'}</div>
                </div>
                <div className={stylesApp.separator}></div>
                <div className={stylesApp.separator}></div>
                <div className={stylesApp.listHeader}>
                    <div id='headerStack' className={clickedHeader === 'headerStack' ? stylesApp.headerItemClicked : stylesApp.headerItem} onClick={(e) => {
                        refreshHeaderClasses(e);
                    }}>Stack</div>
                    <div id='headerPickup' className={clickedHeader === 'headerPickup' ? stylesApp.headerItemClicked : stylesApp.headerItem} onClick={(e) => {
                        refreshHeaderClasses(e);
                    }}>Pickup</div>
                    <div id='headerWeight' className={clickedHeader === 'headerWeight' ? stylesApp.headerItemClicked : stylesApp.headerItem} onClick={(e) => {
                        refreshHeaderClasses(e);
                    }}>{user ? (user.accountType! === 'collector' ? 'Weight' : 'Delivery Code') : ''}</div>
                </div>
                <div className={stylesApp.listHeaderSeparator}></div>
                <List>
                    {
                        listPrograms!.map((item: any, index: number) => {
                            if (user.accountType === 'collector')
                                return (
                                    // <ListItem index={index} onClick={() => { setShowCancelAlert(true) }}>
                                    //     {item}
                                    // </ListItem>
                                    <div className={styles.container} key={item.pkPickup}>
                                        {(index > 0) && <div className={styles.separator}></div>}
                                        <div className={styles.program}>
                                            <div onClick={() => {
                                                setSelectedItem(item);
                                                setShowDetails(true);
                                                //document.location = '/programDetails';
                                            }}>
                                                <div className={styles.category}>{item.displayable}</div>
                                                <div className={styles.location} >{'Istanbul - Fatih'}</div>
                                            </div>
                                            <div onClick={() => {
                                                setShowDetails(true);
                                                //document.location = '/programDetails';
                                            }}>
                                                {/* <Link to={`/programDetails/${item.category}/${item.date}`}> */}
                                                <div className={styles.date}>{item.pickupStart.split('T')[0]}</div>
                                                <div className={styles.period}>
                                                    {`${item.pickupStart.split('T')[1].substring(0, 5)} - ${item.pickupEnd.split('T')[1].substring(0, 5)}`}
                                                </div>
                                                {/* </Link> */}
                                            </div>
                                            <div className={styles.buttonCollector} onClick={() => { setShowCancelAlert(true) }}>
                                                <div>{Math.floor(item.stackVolume)} Kg</div>
                                                <Close color='#FF0404' height={'27px'} />
                                            </div>
                                        </div>
                                    </div>
                                );
                            if (user.accountType === 'consumer')
                                return (
                                    // <ListItem index={index} onClick={() => { setShowCancelAlert(true) }}>
                                    //     {item}
                                    // </ListItem>
                                    <div className={styles.container} key={item.pkPickup}>
                                        {(index > 0) && <div className={styles.separator}></div>}
                                        <div className={styles.program}>
                                            <div onClick={() => {
                                                setSelectedItem(item);
                                                setShowDetails(true);
                                            }}>
                                                <div className={styles.categoryConsumer}>{item.displayable}</div>
                                                <div className={styles.volumeConsumer} >{Math.floor(item.volume)} Kg</div>
                                            </div>
                                            <div style={{ width: '90px' }} onClick={() => {
                                                //setShowDetails(true);
                                                //document.location = '/programDetails';
                                            }}>
                                                {/* <Link to={`/programDetails/${item.category}/${item.date}`}> */}
                                                <div className={styles.collectorConsumer}>{item.collectorFullName}</div>
                                                <div className={styles.dateConsumer}>{item.pickupStart.split('T')[0]}</div>
                                                <div className={styles.periodConsumer}>
                                                    {`${item.pickupStart.split('T')[1].substring(0, 5)} - ${item.pickupEnd.split('T')[1].substring(0, 5)}`}
                                                </div>
                                                {/* </Link> */}
                                            </div>
                                            <div className={styles.buttonConsumer} onClick={() => { 
                                                    setShowCancelAlert(true);
                                                }}>
                                                <div>DC: {item.deliveryCode}</div>
                                            </div>
                                        </div>
                                    </div>
                                );
                        })
                    }
                </List>
                <div className={styles.footer}>
                    <div className={stylesApp.listHeaderSeparator}></div>
                    <div className={styles.info}>
                        <div className={styles.label}>programs:</div>
                        <div className={stylesApp.splitter}></div>
                        <div className={styles.data}>{listPrograms!.length}</div>
                    </div>
                </div>
            </div>
        );
};

export default PickupProgram;