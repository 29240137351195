import { FunctionComponent } from "react";
import { Settings } from "../assets/statics/Settings";

export interface category {
    uId: string|null;
    displayable: string|null;
}

export const getCategories = async (): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/category", {
            method: 'GET',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            })
        }).then((response) => {
            response.json().then((result) => {
                resolve(JSON.stringify({ 'result': result.result }));
            }).catch((error) => {                
                reject(error);
            });
        }).catch((error) => {
            reject(error);
        });
    });
}