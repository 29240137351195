import { FunctionComponent } from "react";
import { Settings } from "../assets/statics/Settings";
import { rejects } from "assert";

export interface user {
    uId: string | null;
    fkUser: string;
    fkCategory: string;
    volume: number;
    fkUnit: string;
    redeemsAt: string;
    deliveryCode: string;
}

export const getViewportActiveStacks = async (
    regionBoundaries: any[],
    selectedCategory: string): Promise<any | undefined> => {
    //console.log('=> {VAS ' + region);
    return new Promise((resolve, reject) => {
        try {
            fetch(Settings.api.path + "/stackLocation/close", {
                method: 'GET',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'AuthenticationToken': 'cyrus#1@Cyrus@React',
                    'queryData': JSON.stringify({ 'regionBoundaries': regionBoundaries, 'categoryId': selectedCategory })
                })
            }).then((response) => {
                response.json().then((result) => {
                    //console.log('-----==={ capturing sp Result: ' + result);
                    resolve(JSON.stringify({ 'result': result.result }));
                }).catch((error) => {
                    reject(error);//JSON.stringify({ 'result': undefined }))
                });
            }).catch((error) => {
                reject(error);//JSON.stringify({ 'result': undefined }));
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getRegionTotalStacks = async (
    regionBoundaries: any[],
    selectedCategory: string): Promise<any | undefined> => {
    //console.log('=> {RTS ' + regionBoundaries);
    return new Promise((resolve, reject) => {
        try {
            fetch(Settings.api.path + "/stackLocation/far", {
                method: 'GET',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'AuthenticationToken': 'cyrus#1@Cyrus@React',
                    'queryData': JSON.stringify({ 'regionBoundaries': regionBoundaries, 'categoryId': selectedCategory })
                })
            }).then((response) => {
                response.json().then((result) => {
                    //console.log('-----==={ capturing sp Result json: ' + result.result);
                    resolve(JSON.stringify({ 'result': result.result }));
                }).catch((error) => {
                    resolve(undefined);
                });
            }).catch((error) => {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const setPickupAppointments = async (
    regionStackIdsList: string,
    userId: string,
    pickupDetails: any): Promise<any | undefined> => {

    return new Promise((resolve, reject) => {
        try {
            fetch(Settings.api.path + "/stackLocation/pickupAppointment", {
                method: 'PATCH',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'AuthenticationToken': 'cyrus#1@Cyrus@React',
                }),
                body: JSON.stringify({
                    regionStackIdsList,
                    collectorId: userId,
                    pickupDetails
                })
            }).then((response) => {
                response.json().then((result) => {
                    resolve(JSON.stringify({ 'result': result.result }));
                }).catch((error) => {
                    reject(JSON.stringify({ 'result': undefined }))
                });
            }).catch((error) => {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}
